import {
  Alert,
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import RankingPercentil from "./rankingPercentil";
import StatusCliente from "./statusCliente";
import api from "../../../../services/api";
//day js
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/pt-br"; // Importa a localidade para português
dayjs.extend(localizedFormat);
dayjs.locale("pt-br"); // Define a localidade como português

export default function ResumoPercentil() {
  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);
  // Funcionamento
  const [rankingPercentil, setRankingPercentil] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCharts, setLoadingCharts] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [controller, setController] = useState(null);
  const [networkSummary, setNetworkSummary] = useState({});
  const [selectedMonth, setSelectedMonth] = useState("actual");
  const [clientFilter, setClienteFilter] = useState(null);
  const [statusClientes, setStatusClientes] = useState({
    excedidos: 0,
    ativos: 0,
  });

  // Meses do seletor
  const mesAtual = dayjs().format("MMMM"); // Nome do mês atual
  const mesPassado = dayjs().subtract(1, "month").format("MMMM"); // Nome do mês passado

  // Carregamento dos dados
  useEffect(() => {
    getResumo();
  }, []);

  // Recebe dados
  const getResumo = async () => {
    try {
      setLoading(true);
      const response = await api.get("/interno/getRanking/percentil");
      setRankingPercentil(response?.data || []);

      setSnackbar({
        children: "Consumo de percentil recebidos com sucesso!",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.response?.data?.error ||
          "Não foi possível se buscar consumo de percentil"
        } `,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Carregamento de dados dos gráficos
  useEffect(() => {
    fetchData();
    // Atualiza a contagem de cliente ativos e clientes excedidos
    setStatusClientes({
      excedidos: Boolean(selectedMonth !== "actual")
        ? rankingPercentil?.filter(
            (cliente) =>
              cliente?.tempoExcedentePeriodoAnterior >= cliente?.tempoMaximo &&
              cliente?.tempoMaximo > 0
          ).length
        : rankingPercentil?.filter(
            (cliente) =>
              cliente?.tempoExcedente >= cliente?.tempoMaximo &&
              cliente?.tempoMaximo > 0
          ).length,
      ativos: Boolean(selectedMonth !== "actual")
        ? rankingPercentil.filter(
            (cliente) => cliente?.consumoPercentilPeriodoAnterior > 0
          ).length
        : rankingPercentil.filter((cliente) => cliente?.consumoPercentil > 0)
            .length,
    });
  }, [selectedRow, selectedMonth]);

  // Requisição dos dados das dashs
  const fetchData = async () => {
    setLoadingCharts(true);
    if (controller) {
      controller.abort(); // Abortar a requisição anterior
    }

    const newController = new AbortController(); // Criar novo AbortController
    const signal = newController.signal; // Obter o sinal

    setController(newController); // Guardar o novo controller no estado

    try {
      if (selectedRow?.percentilCliente?.cliente?.id) {
        await Promise.all([apiHandleNetworkSummary(signal)]);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Erro durante a solicitação de rotas"
        }`,
        severity: "error",
      });
    } finally {
      setLoadingCharts(false);
    }
  };

  async function apiHandleNetworkSummary(signal) {
    try {
      const response = await api.post(
        "/interno/getRanking/history",
        {
          id: selectedRow?.id || "",
          previousMonth: Boolean(selectedMonth !== "actual"),
        },
        { signal }
      );

      if (response.data.status === "Error") {
        console.log("Error: Não foi possível carregar os valores");
      } else {
        if (response?.data) {
          setNetworkSummary(response?.data);
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de networkSummary cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível buscar informações da rede"
          } `,
          severity: "error",
        });
      }
    } finally {
    }
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Box sx={styles.filterBox}>
          <Typography>Cliente</Typography>
          <Autocomplete
            sx={{ ...styles.formControl, minWidth: "280px" }}
            value={clientFilter}
            size="small"
            options={rankingPercentil}
            getOptionLabel={(option) =>
              option.percentilCliente.cliente.nomeFantasia
            }
            onChange={(event, newValue) => {
              setClienteFilter(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box sx={styles.filterBox}>
          <Typography sx={styles.filterTypograph}>
            Intervalo de tempo
          </Typography>
          <FormControl sx={styles.formControl} size="small">
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={selectedMonth || ""}
              onChange={(e) => {
                setSelectedMonth(e?.target?.value);
              }}
            >
              <MenuItem value={"actual"}>
                {mesAtual[0]?.toUpperCase() + mesAtual?.substring(1)}
              </MenuItem>
              <MenuItem value={"last"}>
                {mesPassado[0]?.toUpperCase() + mesPassado?.substring(1)}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={styles.content}>
        <Box sx={styles.ranking}>
          <RankingPercentil
            data={rankingPercentil}
            loading={loading}
            setSnackbar={setSnackbar}
            setSelectedRow={setSelectedRow}
            clientFilter={clientFilter}
            selectedMonth={selectedMonth}
          />
        </Box>

        <Box sx={styles.status}>
          <StatusCliente
            ativos={statusClientes?.ativos}
            excedidos={statusClientes?.excedidos}
            networkSummary={networkSummary}
            loadingCharts={loadingCharts}
            selectedRow={selectedRow}
          />
        </Box>

        {!!snackbar && (
          <Snackbar
            open
            onClose={() => setSnackbar(null)}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
          </Snackbar>
        )}
      </Box>
    </Box>
  );
}
