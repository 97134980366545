import { useTheme } from "@mui/system";
import useStyles from "./styles";
import "dayjs/locale/pt-br";
import {
  Box,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import api from "../../../../services/apiCliente";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Stack } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/pt-br"; // Importa a localidade para português
import { DateTimePicker } from "@mui/x-date-pickers";
import { pdf } from "@react-pdf/renderer";
import { PageTemplateEasyReport } from "../../../../components/generateReport";
import { saveAs } from "file-saver";
import { LoadingButton } from "@mui/lab";
import { renderToStaticMarkup } from "react-dom/server";
import TrafficChart from "../../../../components/relatorioPercentil/line/chartsTypes/traficChart";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.locale("pt-br"); // Define a localidade como português

export default function RelatorioFlowCliente() {
  // Rstilização
  const theme = useTheme();
  const styles = useStyles(theme);
  // Informações do cliente
  const cliente =
    JSON.parse(localStorage.getItem("userCliente"))?.usuarioCliente || "";
  // Configurações de data/calendário
  const today = new Date();
  today?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  today?.setSeconds(0, 0);
  const twoMonths = new Date();
  twoMonths?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  twoMonths?.setMonth(today.getMonth() - 2);
  twoMonths?.setHours(0, 0, 0, 0);
  const defaultDateInicial = new Date();
  defaultDateInicial.setMonth(today.getMonth() - 1);
  defaultDateInicial?.setHours(0, 0, 0, 0);
  const defaultDateFinal = new Date();
  defaultDateFinal?.setHours(0, 0, 0, 0);
  const mesAtual = dayjs().format("MMMM"); // Nome do mês atual
  const mesPassado = dayjs().subtract(1, "month").format("MMMM");
  // Variáveis da manipulação
  const [percentil, setPercentil] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const [excendete, setExcedente] = useState(false);
  const [Null, setNull] = useState(false);
  const [invalidDateInicial, setInvalidDateInicial] = useState(false);
  const [invalidDateFinal, setInvalidDateFinal] = useState(false);
  const [errorDateInicial, setErrorDateInicial] = useState(null);
  const [errorDateFinal, setErrorDateFinal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valorExecedente, setValorExcedente] = useState();
  const [selectedMonth, setSelectedMonth] = useState("actual");
  const [formData, setFormData] = useState({
    titulo: cliente.cliente.nomeFantasia,
    status: 0,
    tipo: 0,
    dataInicial: "",
    dataFinal: "",
    clienteId: cliente?.clienteId,
    tarifacaoExcedente: {
      status: true,
      valor: "0,00",
      valorExcedente: "0",
      limite: "0",
    },
  });

  // Requisições ao carregar a página
  useEffect(() => {
    handlePercentil();
  }, []);
  useEffect(() => {
    setValorExcedente(
      (
        (parseFloat(percentil?.tarifacaoExcedente) *
          convertNumero(percentil?.tarifacaoFixa)) /
          100 || 0
      )
        ?.toFixed(2)
        ?.replace(".", ",")
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  }, [percentil?.tarifacaoExcedente, percentil?.tarifacaoFixa]);

  useEffect(() => {
    if (selectedMonth === "last") {
      let final_date = new Date();
      let initial_date = new Date();
      initial_date = dayjs(initial_date)
        .subtract(1, "month")
        .date(1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);
      final_date = dayjs(final_date)
        .date(1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);
      setFormData((prev) => ({
        ...prev,
        dataInicial: initial_date.toISOString(),
        dataFinal: final_date.toISOString(),
      }));
    } else {
      let final_date = new Date();
      let initial_date = new Date();
      initial_date = dayjs(initial_date)
        .date(1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);
      final_date = dayjs(final_date)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);

      setFormData((prev) => ({
        ...prev,
        dataInicial: initial_date.toISOString(),
        dataFinal: final_date.toISOString(),
      }));
    }
  }, [selectedMonth]);

  async function handleApiGetBase64(htmlBase64) {
    try {
      const response = await api.post("/cliente/relatorio/htmlToImageBase64", {
        htmlBase64,
      });

      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleGenerateGraphPNG(dataIn, percentil) {
    let graphOut = {};
    let [data] = dataIn;
    let percentilClients = {};
    const { history, type, th95, trafficFull, limitTraffic } = data;
    let staticHtmlTraffic = renderToStaticMarkup(
      <TrafficChart
        data={history ? history : []}
        percentil={percentil}
        c95th={th95}
        limitTraffic={limitTraffic}
      />
    );
    percentilClients = {
      name: type,
      value: trafficFull,
      status: true,
    };
    let graphcs = await handleApiGetBase64({
      data: btoa(unescape(encodeURIComponent(staticHtmlTraffic))),
      //data: btoa(decodeURIComponent(encodeURIComponent(staticHtmlTraffic))),
      width: 790,
      height: 200,
    });

    graphOut = {
      client: data.client,
      type,
      graphcs: graphcs,
    };

    return graphOut;
  }

  // Recebimento do percentil a partir do banco
  const handlePercentil = async () => {
    try {
      const response = await api.post("/cliente/percentilCliente", {
        clienteId: cliente?.clienteId,
      });

      setPercentil(response?.data);
    } catch (erro) {
      console.error(erro);
    } finally {
    }
  };

  const convertNumero = (value) => {
    return parseFloat(value?.replace(/\./g, "")?.replace(",", "."));
  };

  // Validação do datapicker
  const errorMensegeDateInicial = useMemo(() => {
    switch (errorDateInicial) {
      case "maxDate": {
        setInvalidDateInicial(true);
        return "Data inicial ultrapassou a data final";
      }
      case "maxTime": {
        setInvalidDateInicial(true);
        return "Data inicial ultrapassou a data final";
      }
      case "minDate": {
        setInvalidDateInicial(true);
        return "Data ultrapassou limite de 2 meses atrás";
      }
      case "minTime": {
        setInvalidDateInicial(true);
        return "Data ultrapassou limite de 2 meses atrás";
      }

      case "invalidDate": {
        setInvalidDateInicial(true);
        return "Necessário informar a data completa";
      }

      default: {
        setInvalidDateInicial(false);
        return "";
      }
    }
  }, [errorDateInicial]);
  const errorMensegeDateFinal = useMemo(() => {
    switch (errorDateFinal) {
      case "maxDate": {
        setInvalidDateFinal(true);
        return "Data final inválida!";
      }
      case "maxTime": {
        setInvalidDateFinal(true);
        return "Data final inválida!";
      }
      case "minDate": {
        setInvalidDateFinal(true);
        return "Data ultrapassou limite de 2 meses atrás";
      }
      case "minTime": {
        setInvalidDateFinal(true);
        return "Data ultrapassou limite de 2 meses atrás";
      }

      case "invalidDate": {
        setInvalidDateFinal(true);
        return "Necessário informar a data completa";
      }

      default: {
        setInvalidDateFinal(false);
        return "";
      }
    }
  }, [errorDateFinal]);

  // Requisição
  const handleDados = async () => {
    if (
      !formData?.titulo?.trim() ||
      !formData?.dataFinal ||
      !formData?.dataFinal ||
      invalidDateFinal ||
      invalidDateInicial
    ) {
      setNull(true);
    } else {
      setLoading(true);
      try {
        let graphcs = null;
        const response = await api.post("/cliente/relatorio", {
          clienteId: cliente?.clienteId,
          initial_date: formData?.dataInicial,
          final_date: formData?.dataFinal,
          tarifacao: formData?.tarifacaoExcedente?.valor,
          fonte: "easyflow",
        });
        console.log("aqui");

        const relatorio = {
          titulo: formData?.titulo,
          client: response?.data?.client,
          status: 0,
          tipo: 1,
          dataInicial: formData?.dataInicial,
          dataFinal: formData?.dataFinal,
          hideCharts: false,
          percentil: percentil?.percentil || 95,
          analysis: response?.data?.analysis,
          interfaces: response?.data?.interfaces,
          tarifacaoExcedente: {
            status: percentil?.cobrancaPorExcedente,
            valor: convertNumero(percentil?.tarifacaoFixa),
            valorExcedente:
              convertNumero(percentil?.tarifacaoExcedente) +
              convertNumero(percentil?.tarifacaoFixa),
            limite: parseInt(percentil?.valorExcedente),
          },
        };

        graphcs = await handleGenerateGraphPNG(
          relatorio.analysis,
          relatorio.percentil
        );

        const MyDoc = (
          <PageTemplateEasyReport relatorio={relatorio} graphcs={graphcs} />
        );
        let blob = await pdf(MyDoc).toBlob();
        saveAs(blob, `relatório ${relatorio?.client}.pdf`);

        setSnackbar({
          children: "Gerado relatório",
          severity: "success",
        });
        setNull(false);
      } catch (error) {
        let dataError = error?.response?.data;
        if (dataError) {
          setSnackbar({
            children: dataError?.message,
            severity: "error",
          });
        } else {
          setSnackbar({
            children: "Erro ao atualizar informações do perfil",
            severity: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box sx={styles.container}>
      {/* <Box
        sx={{
          display: "flex",
          //maxWidth: "850px",
          width: "100%",
          justifyContent: "center",
        }}
      > */}
      <Paper
        sx={styles.containerPaper}
        elevation={3}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
      >
        <Typography sx={{ fontSize: "21px" }}>Relatório</Typography>
        <Paper variant="outlined" sx={styles.paper}>
          <Stack direction={"column"}>
            <Typography sx={styles.subtitle} variant="subtitle2">
              Título do relatório
            </Typography>
            <TextField
              size="small"
              label="Título"
              variant="filled"
              autoComplete="off"
              defaultValue={cliente.cliente.nomeFantasia}
              helperText={
                !formData?.titulo?.trim() && Null
                  ? "Necessário informar um título"
                  : ""
              }
              disabled={loading}
              error={!formData?.titulo?.trim() && Null}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, titulo: e.target.value }))
              }
              sx={{ minWidth: "200px" }}
            />
          </Stack>
          <Divider sx={styles.divider}></Divider>

          <Typography sx={styles.subtitle} variant="subtitle2">
            Valor por Mbps
          </Typography>

          <Box sx={styles.stack}>
            <Box sx={styles.containerCheck}>
              <FormControl
                error={!formData?.tarifacaoExcedente?.valor && Null}
                size="small"
                variant="filled"
                sx={styles.valorMbps}
              >
                <InputLabel>Valor por Mbps</InputLabel>
                <FilledInput
                  value={percentil?.tarifacaoFixa}
                  startAdornment={
                    <InputAdornment position="start">R$</InputAdornment>
                  }
                  disabled={true}
                />
              </FormControl>
            </Box>

            {percentil?.cobrancaPorExcedente && (
              <>
                <FormControl
                  size="small"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  <InputLabel
                    error={!formData?.tarifacaoExcedente?.limite && Null}
                    htmlFor="filled-adornment-amount"
                  >
                    Limite de tráfego
                  </InputLabel>
                  <FilledInput
                    value={percentil?.valorExcedente || ""}
                    disabled={true}
                  />
                  <FormHelperText id="filled-weight-helper-text">
                    Em Mbps
                  </FormHelperText>
                </FormControl>
                <FormControl
                  size="small"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  <InputLabel
                    error={
                      !formData?.tarifacaoExcedente?.valorExcedente && Null
                    }
                    htmlFor="filled-adornment-amount"
                  >
                    Excedente em %
                  </InputLabel>
                  <FilledInput
                    value={
                      Math.round(
                        (convertNumero(percentil?.tarifacaoExcedente) * 100) /
                          convertNumero(percentil?.tarifacaoFixa).toFixed(2)
                      ) || 0
                    }
                    disabled={true}
                  />
                  <FormHelperText id="filled-weight-helper-text">
                    R$ {percentil?.tarifacaoExcedente}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          </Box>

          <Divider sx={styles.divider}></Divider>
          <Typography sx={styles.subtitle} variant="subtitle2">
            Intervalo de coleta
          </Typography>

          <Box sx={styles.containerDate}>
            <FormControl sx={styles.formControl} size="small">
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedMonth || ""}
                onChange={(e) => {
                  setSelectedMonth(e?.target?.value);
                }}
                disabled={loading}
              >
                <MenuItem value={"actual"}>
                  {mesAtual[0]?.toUpperCase() + mesAtual?.substring(1)}
                </MenuItem>
                <MenuItem value={"last"}>
                  {mesPassado[0]?.toUpperCase() + mesPassado?.substring(1)}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Divider sx={styles.divider}></Divider>
          <Stack spacing={2} alignItems={"end"}>
            <LoadingButton
              loading={loading}
              onClick={handleDados}
              variant="contained"
              size="medium"
            >
              Gerar relatório
            </LoadingButton>
          </Stack>
        </Paper>
      </Paper>
      {/* </Box> */}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
