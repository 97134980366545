const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "850px",
    gap: 3,
    width: "100%",
    marginTop: "-12px",
  },
  containerPaper: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
  },
  tituloComponent: {
    fontSize: "21px",
  },
  chip: {
    height: "auto",
    "& .MuiChip-label": {
      display: "block",
      whiteSpace: "normal",
    },

    color: "#ffffff",
  },
  paper: {
    padding: "40px",
    margin: "20px 0",
    backgroundColor: "transparent",
  },
  containerCheck: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
  },
  valorMbps: {
    width: "100%",
    maxWidth: "235px",
    "@media (max-width: 1040px)": {
      margin: "0 0 14px 0",
      maxWidth: "100%",
    },
  },
  diaVencimento: {
    width: "100%",
  },
  divider: {
    margin: "15px 0 30px 0",
  },
  subtitle: {
    margin: "0 0 10px 0",
  },
  radioGrupo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  stack: {
    minHeight: "75px",
    display: "flex",
    flexDirection: "row",
    spacing: "20px",
    gap: "10px",

    "@media (max-width: 1040px)": {
      flexDirection: "column",
    },
  },
  opacityStyle: {
    opacity: 0.7,
  },
  containerExcedente: {
    marginBottom: "20px",
  },
  formInput: {
    margin: "0 10px",
  },
  titlePercentil: {
    width: "200px",
    marginTop: "20px",
  },
  button: {
    margin: "0 10px",
  },
  containerButton: {
    width: "100%",
    padding: "10px",
  },
  containerConfig: {
    display: "flex",
    alignItems: "center",
    "@media (max-width: 1040px)": {
      flexDirection: "column",
    },
  },
  containerDate: {
    display: "flex",
    gap: "10px",
    "@media (max-width: 1040px)": {
      flexDirection: "column",
    },
  },
  containerPermissao: {
    width: "100%",

    display: "flex",
    flexDirection: "column",
  },
  containerTitle: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1040px)": {
      flexDirection: "column",
    },
  },
  containerSelect: {
    display: "flex",
    flexDirection: "column",
    mb: "10px",
  },
  containerLoadindButton: {
    spacing: 2,
    alignItems: "end",
    "@media (max-width: 1040px)": {
      alignItems: "center",
    },
  },
  containerSaveButton: {
    display: "flex",
  },
});
export default styles;
