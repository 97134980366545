import React, { useMemo, useState } from "react";
import {
  ThemeProvider,
  useMediaQuery,
  CssBaseline,
  Box,
  createTheme,
} from "@mui/material";
import { z } from "zod";
import theme from "./theme";
import useStyles from "./styles";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/internal/login";
import LayoutClient from "./pages/client/layout";
import Layout from "./pages/internal/layout";
import Home from "./pages/internal/home";
import LoginClient from "./pages/client/login";
import HomeClient from "./pages/client/home";
import AdminPanel from "./pages/internal/administracao";
import JuniperScreen from "./pages/internal/juniperScreen";
import Cadastro from "./pages/internal/cadastro";
import LayoutCadastro from "./pages/internal/layoutCadastro";
import { RangeProvider } from "./pages/client/range";
import CadastroCliente from "./pages/client/cadastro";
import HomeAdm from "./pages/internal/homeAdm";
import RedefinirSenha from "./pages/internal/redefinirSenha";
import LayoutCadastroCliente from "./pages/internal/layoutCadastro";
import SolicitarRedefinirSenha from "./pages/internal/solicitarRedefinirSenha";
import SolicitarRedefinirSenhaCliente from "./pages/client/solicitarRedefinirSenha";
import RedefinirSenhaCliente from "./pages/client/redefinirSenha";
import Perfil from "./pages/internal/perfil";
import JuniperConfig from "./pages/internal/perfil/juniperConfig";
import InternoPerfilCliente from "./pages/internal/perfil/cadastro";
import Financeiro from "./pages/internal/perfil/financeiro";
import Status from "./pages/internal/status";
import Configuracoes from "./pages/internal/configuracoes";
import FinanceiroCliente from "./pages/client/financeiro";
import Recibo from "./pages/client/financeiro/recibo";
import ConfiguracoesCliente from "./pages/client/configuracoes";
import Wanguard from "./pages/internal/perfil/wanguard";
import AdminPainelCliente from "./pages/client/administracao";
//import Auditoria from "./pages/internal/administracao/auditoria";
//import RegistroLogin from "./pages/internal/administracao/registroLogin";

import RelatoriosFlow from "./pages/internal/perfil/easyFlow/relatoriosFlow";
import Relatorios from "./pages/internal/perfil/relatorios";
import RelatoriosCliente from "./pages/client/relatorios";
import PercentilCliente from "./pages/client/relatorios/percentil";
import RelatorioFlowCliente from "./pages/client/relatorios/relatorioFlow";
import ClientRoute from "./routes/clientes";
import ClientViewpoint from "./pages/internal/administracao/clientes/modoEspelho";
import PainelDeLogs from "./pages/internal/administracao/auditoria";
import Auditoria from "./pages/internal/administracao/auditoria/registroAcoes";
import RegistroLogin from "./pages/internal/administracao/auditoria/registroLogin";
import ResumoPercentil from "./pages/internal/administracao/resumoPercentil";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [colorMode, setColorMode] = useState(prefersDarkMode);
  const styles = useStyles(colorMode);
  const myTheme = useMemo(() => theme(colorMode), [colorMode]);

  return (
    <ThemeProvider theme={myTheme}>
      <CssBaseline />
      <Box
        className="App"
        sx={{ backgroundColor: "background.wallpaper", minHeight: "100vh" }}
      >
        <RangeProvider>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Navigate replace to="/login" />} />
              <Route exact path="/login" element={<LoginClient />} />
              <Route exact path="/interno/login" element={<Login />} />
              <Route
                element={
                  <LayoutCadastroCliente
                    colorMode={colorMode}
                    setColorMode={setColorMode}
                  />
                }
              >
                <Route path="/cliente/cadastro" element={<CadastroCliente />} />

                <Route
                  exact
                  path="/cliente/redefinirSenha"
                  element={<SolicitarRedefinirSenhaCliente />}
                />
                <Route
                  exact
                  path="/cliente/redefinirSenha/:token/:id"
                  element={<RedefinirSenhaCliente />}
                />
              </Route>
              <Route
                element={
                  <LayoutCadastro
                    colorMode={colorMode}
                    setColorMode={setColorMode}
                  />
                }
              >
                <Route path="/interno/cadastro" element={<Cadastro />} />

                <Route
                  exact
                  path="/interno/redefinirSenha"
                  element={<SolicitarRedefinirSenha />}
                />
                <Route
                  exact
                  path="/interno/redefinirSenha/:token/:id"
                  element={<RedefinirSenha />}
                />
              </Route>
              <Route
                element={
                  <Layout colorMode={colorMode} setColorMode={setColorMode} />
                }
              >
                <Route path="/interno/home" element={<Home />} />
                <Route path="/interno/home/perfil" element={<Perfil />}>
                  <Route
                    path="/interno/home/perfil/:id"
                    element={<InternoPerfilCliente />}
                  />
                  <Route
                    path="/interno/home/perfil/juniper/:id"
                    element={<JuniperConfig />}
                  />
                  <Route
                    path="/interno/home/perfil/financeiro/:id"
                    element={<Financeiro />}
                  />
                  <Route
                    path="/interno/home/perfil/wanguard/:id"
                    element={<Wanguard />}
                  />
                  <Route
                    path="/interno/home/perfil/easyflow/:id"
                    element={<RelatoriosFlow />}
                  />
                  <Route
                    path="/interno/home/perfil/relatorios/:id"
                    element={<Relatorios />}
                  />
                </Route>
                <Route path="/interno/dash" element={<HomeAdm />} />
                <Route path="/interno/administracao" element={<AdminPanel />} />
                <Route path="/interno/auditoria" element={<PainelDeLogs />}>
                  <Route path="/interno/auditoria" element={<Auditoria />} />
                  <Route
                    path="/interno/auditoria/acessos"
                    element={<RegistroLogin />}
                  />
                </Route>
                <Route
                  path="/interno/configuracoes"
                  element={<Configuracoes />}
                />
                <Route path="/interno/status" element={<Status />} />
                <Route
                  path="/interno/Juniperscreen"
                  element={<JuniperScreen />}
                />
                <Route path="/interno/home/perfil" element={<Perfil />} />
                <Route path="/interno/resumo" element={<ResumoPercentil />} />
              </Route>

              {ClientRoute({
                pathName: "",
                colorMode: { colorMode },
                setColorMode,
              })}
              <Route path="/mirror">
                {ClientRoute({
                  pathName: "/mirror",
                  colorMode: { colorMode },
                  setColorMode,
                })}
              </Route>
              <Route
                path="/financeiro/recibo/:invoice_pk"
                element={<Recibo />}
              />
            </Routes>
          </BrowserRouter>
        </RangeProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
