import { maxHeight, padding, width } from "@mui/system";

const styles = (theme) => ({
  apiTitulo: {
    fontSize: "16px",
    fontWeight: 600,
  },
  apiTwo: {
    fontSize: "16px",
    fontWeight: 600,
  },
  apiThree: {
    fontSize: "16px",
    fontWeight: 600,
  },
  apiFour: {
    fontSize: "16px",
    fontWeight: 600,
  },

  containerApi: {
    marginBottom: "20px",
  },
  dayContainer: {
    height: "36px",
    width: "6px",
    backgroundColor: theme.palette.mode === "dark" ? "#2E7D32" : "#00A76F",
  },

  containerApiOne: {
    bgcolor: theme.palette.mode === "dark" ? "#ad1457" : "#880e4f",
    width: "14px",
    height: "14px",
    borderRadius: "2px",

    marginRight: "5px",
  },
  containerApiTwo: {
    bgcolor: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
    width: "14px",
    height: "14px",
    borderRadius: "2px",

    marginRight: "5px",
  },
  containerApiThree: {
    bgcolor: theme.palette.mode === "dark" ? "#42a5f5" : "#01579b",
    width: "14px",
    height: "14px",
    borderRadius: "2px",

    marginRight: "5px",
  },
  containerApiFour: {
    bgcolor: theme.palette.mode === "dark" ? "#9575cd" : "#4a148c",
    width: "14px",
    height: "14px",
    borderRadius: "2px",

    marginRight: "5px",
  },
  containerStatusRequisicao: {
    bgcolor: theme.palette.mode === "dark" ? "#388e3ca8" : "#3ba55c",
    width: "10px",
    height: "10px",
    borderRadius: "10px",
    marginRight: "5px",
  },
  containerStatusFalha: {
    bgcolor: theme.palette.mode === "dark" ? "#D9C91A" : "#eedd1e",
    width: "10px",
    height: "10px",
    borderRadius: "10px",
    marginRight: "5px",
  },
  containerStautsAnalise: {
    width: "10px",
    height: "10px",
    borderRadius: "10px",
    marginRight: "5px",
  },
  containerIncidentes: {
    width: "100%",
    marginTop: "10px",
    maxHeight: "250px",
    overflow: "auto",
  },
  containerComponent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  containerLoading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  tituloComponent: {
    fontSize: "20px",
    fontWeight: 600,
    mb: "16px",
    mt: "16px",
  },
  containerPaper: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    minWidth: "52%",
  },
  containerTituloApi: {
    height: "40px",
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    alignItems: "center",
  },
  containerAnaliseApi: {
    display: "flex",
    flexDirection: "row",
    gap: "3px",
    mb: "16px",
    justifyContent: "center",
  },
  containerData: {
    marginBottom: "5px",
  },
  tituloData: {
    fontSize: "13px",
    fontWeight: "600",
  },
  containerRequisicao: {
    display: "flex",
    alignItems: "center",
  },
  tituloRequisicao: {
    fontSize: "13px",
  },
  containerFalha: {
    display: "flex",
    alignItems: "center",
  },
  containerStatusIncidentes: {
    display: "flex",
    justifyContent: "space-between",
  },
  containerSemDados: {
    marginBottom: "5px",
  },
  containerColorApi: {
    width: "14px",
    height: "14px",
    borderRadius: "2px",
    marginRight: "10px",
  },
  containerDia: {
    height: "36px",
    width: "6px",
  },
  containerAnaliseDias: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "8px",
  },
  tituloDias: {
    fontSize: "12px",
  },
  containerDivider: {
    width: "100%",
  },
  containerHoje: {
    minWidth: "30px",
  },
  containerRespostaApi: {
    display: "flex",
    justifyContent: "space-between",
  },
  tituloRespostaApi: {
    fontSize: "16px",
    mb: "8px",
    mt: "16px",
  },
  containerGrafico: {
    display: "flex",
    width: "100%",
    height: "200px",
    flexDirection: "column",
  },
  containerForm: {
    width: "170px",
  },
});

export default styles;
