import axios from "axios";
import tokenUpdate from "./tokenUpdate";

const token = () => {
  let result = localStorage.getItem("user");
  result = JSON.parse(result);
  //console.log(result);
  if (result) {
    return result.token;
  } else {
    return "";
  }
};
const usuarioId = () => {
  let result = localStorage.getItem("user");
  result = JSON.parse(result);
  //console.log(result.usuarioId);
  if (result) {
    //const codigos = ;
    return result?.usuario?.id || "";
  } else {
    return "";
  }
};

const logsLoginId = () => {
  let result = localStorage.getItem("logs");
  result = JSON.parse(result);
  if (result) {
    return result?.id;
  } else {
    return "";
  }
};

const api = axios.create({});

api.interceptors.request.use(async (config) => {
  const url = `${
    window?.config?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || ""
  }`;

  const path = window.location.pathname;
  const isMirror = path.includes("mirror");
  if (isMirror) {
    localStorage.removeItem("logInternoCliente");
    localStorage.removeItem("userCliente");
    localStorage.removeItem("perfilCliente");
  }

  if (token()) {
    try {
      const tokenAtual = token();
      const user = usuarioId();
      const loginId = logsLoginId();
      config.baseURL = url;
      config.headers.Authorization = tokenAtual ? `Bearer ${tokenAtual}` : "";
      config.headers["x-user-id"] = user ? `${user}` : "";
      config.headers["x-logslogin-id"] = loginId ? `${loginId}` : "";
      // atualiza o token an tes de continuar com a requisição
      // await tokenUpdate(token());
      return config;
    } catch (error) {
      console.error(error);
    }
  } else {
    localStorage.clear();
    window.location.replace("/login");
  }
});

export default api;
