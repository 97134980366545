import {
  Box,
  Paper,
  Snackbar,
  Alert,
  Stack,
  Button,
  Tooltip,
  IconButton,
  useTheme,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Autocomplete,
  Chip,
  createFilterOptions,
  Typography,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import api from "../../../../../../services/api";
import {
  Add,
  Delete,
  Edit,
  CheckCircleOutlineOutlined,
  DoNotDisturbAltOutlined,
  MoreVertOutlined,
  CheckBoxOutlineBlank,
  MoreHoriz,
  ArrowDropDownOutlined,
  Check,
} from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditModalBlocos from "./editModalBlocos";
import CreateModalBlocos from "./createModalBlocos";
import { ptBR } from "@mui/x-data-grid/locales";
import useStyles from "./styles";
import InternoModalDeleteCliente from "../../../../../../components/internoModalDeleteCliente";
import { useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import { socketInterno as socket } from "../../../../../../services/socket";
import { height, maxHeight } from "@mui/system";
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function Blocos(params) {
  const [si, clienteInfo, sb] = useOutletContext();
  const clienteId = clienteInfo?.id || "";
  const theme = useTheme();
  const styles = useStyles(theme);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteModalGrupo, setOpenDeleteModalGrupo] = useState(false);
  const [openRemoveModalGrupo, setOpenRemoveModalGrupo] = useState(false);
  const [blocos, setBlocos] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingRemoveGrupo, setLoadingRemoveGrupo] = useState(false);
  const [idGrupoDelete, setIdGrupoDelete] = useState("");
  const [loadingChecked, setLoadingChecked] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [selectBloco, setSelectBloco] = useState([]);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElOptionsGrupo, setAnchorElOptionsGrupo] = useState(null);
  const [anchorElGrupos, setAnchorElGrupos] = useState(null);
  const [loadingManyStatus, setLoadingManyStataus] = useState(false);
  const [loadingHandleGrupo, setLoadingHandleGrupo] = useState(false);
  const [handleGrupo, setHandleGrupo] = useState({
    grupoName: "",
    colorGrupo: "",
  });
  const [grupoExist, setGrupoExist] = useState(false);
  const [gruposRow, setGruposRow] = useState([]);
  const [thisRowGrupo, setThisRowGrupo] = useState({});
  const [buscarGrupo, setBuscarGrupo] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [grupos, setGrupos] = useState([]);
  const [grupoId, setGrupoId] = useState("");
  const [blocoId, setBlocoId] = useState("");
  const [openRemoveManyModalGrupo, setOpenRemoveManyModalGrupo] =
    useState(false);

  const openMenuStatus = Boolean(anchorElStatus);
  const openMenuGrupos = Boolean(anchorElGrupos);
  const openMenuOptionsGrupo = Boolean(anchorElOptionsGrupo);
  const open = Boolean(anchorEl);

  const filter = createFilterOptions();

  const deleteBody =
    "O item será deletado permanentemente. Deseja deletar o item?";
  const deleteBodyManyGrupos =
    "Deseja remover todos os grupos dos itens selecionados?";
  const confirmDelete = "Excluir";
  const deleteBodyRemoveGrupo =
    "O bloco será removido desse grupo. Deseja remover o bloco do grupo?";
  const confirmRemoveGrupo = "Remover";
  const colorChip = [
    "chip.pending",
    "chip.completed",
    "chip.inProgress",
    "chip.waitingReview",
    "chip.waitingReturn",
    "chip.arquive",
  ];

  const filteredRows = Array.isArray(grupos)
    ? grupos?.filter((row, index) => {
        row.numero = index;
        return row.grupoName
          ?.toLowerCase()
          ?.includes(buscarGrupo?.toLowerCase());
      })
    : [];

  useEffect(() => {
    if (clienteId) {
      handleApiGetBlocos();
      findManyGrupos();
    }
  }, [clienteId]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }

    socket.connect();
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", connectErrorEvent);
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
      socket.disconnect();
    };
  }, []);

  const addGrupoBloco = async (blocoId, grupoId) => {
    setLoadingHandleGrupo(true);
    try {
      socket.emit(
        "add_RlGrupo",
        {
          blocoId: blocoId,
          grupoId: grupoId?.id,
        },
        (response) => {
          if (response?.data) {
            setGruposRow((grupoRow) => [...grupoRow, grupoId]);
            setBlocos((blocos) =>
              blocos?.map((bloco) =>
                bloco?.id === response?.data?.blocoId
                  ? {
                      ...bloco,
                      RlGruposBlocosFlow: [
                        ...bloco?.RlGruposBlocosFlow,
                        response?.data,
                      ],
                    }
                  : bloco
              )
            );

            setSnackbar({
              children: "Status do bloco atualizado",
              severity: "success",
            });
          } else {
            setSnackbar({
              children: "Error: Não foi atualizar status do bloco",
              severity: "error",
            });
          }
        }
      );

      setSelectBloco([]);
      setSelectedRow([]);

      setSnackbar({
        children: "Adicionado blocos ao grupo",
        severity: "success",
      });
      setBuscarGrupo("");
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível atualizar o grupo",
        severity: "error",
      });
      console.error(error);
    } finally {
      setLoadingHandleGrupo(false);
      handleCloseMenuGrupos();
    }
  };

  const addManyBlocosGrupos = async (blocosId, grupoId) => {
    setLoadingHandleGrupo(true);
    try {
      const response = await api.post("/interno/createMany/rlGrupoBlocosFlow", {
        blocosId: blocosId,
        grupoId: grupoId,
      });
      setBlocos((blocos) =>
        blocos?.map((bloco) => {
          const blocoAtualizado = response?.data?.find(
            (responseBloco) => responseBloco?.blocoId === bloco?.id
          );

          if (blocoAtualizado) {
            return {
              ...bloco,
              RlGruposBlocosFlow: [
                ...bloco?.RlGruposBlocosFlow,
                blocoAtualizado,
              ],
            };
          }

          return bloco;
        })
      );

      setSnackbar({
        children: "Adicionado blocos ao grupo",
        severity: "success",
      });
      setSelectBloco([]);
      setSelectedRow([]);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível atualizar o grupo",
        severity: "error",
      });
    } finally {
      setLoadingHandleGrupo(false);
      handleCloseMenuGrupos();
    }
  };

  const deleteGrupo = async () => {
    setLoadingHandleGrupo(true);

    try {
      const response = await api.post("/interno/delete/grupoBlocosFlow", {
        grupoId: idGrupoDelete,
      });
      const newGrupos = grupos?.filter((grupo) => grupo?.id !== idGrupoDelete);
      setGrupos(newGrupos);
      setGruposRow((gruposRow) =>
        gruposRow?.filter((grupoRow) => grupoRow?.id !== idGrupoDelete)
      );
      setIdGrupoDelete("");
      setSnackbar({
        children: "Grupo deletado",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível deletar o grupo",
        severity: "error",
      });
    } finally {
      setLoadingHandleGrupo(false);
      setOpenDeleteModalGrupo(false);
      handleClose();
    }
  };

  const deleteBlocosGrupos = async (blocoId, grupoId) => {
    setLoadingHandleGrupo(true);
    setLoadingRemoveGrupo(true);
    try {
      socket.emit(
        "remove_RlGrupo",
        {
          blocoId: blocoId,
          grupoId: grupoId?.id,
        },
        (response) => {
          if (response?.data) {
            setGruposRow((grupoRow) =>
              grupoRow?.filter((grupo) => grupo?.id !== grupoId?.id)
            );

            setBlocos((blocos) =>
              blocos?.map((bloco) =>
                bloco?.id === blocoId
                  ? {
                      ...bloco,
                      RlGruposBlocosFlow: bloco?.RlGruposBlocosFlow?.filter(
                        (grupo) => grupo?.grupoId !== grupoId?.id
                      ),
                    }
                  : bloco
              )
            );

            setSnackbar({
              children: "Blocos retirados do grupo",
              severity: "success",
            });
          } else {
            setSnackbar({
              children: "Error: Não foi atualizar status do bloco",
              severity: "error",
            });
          }
        }
      );
      setBuscarGrupo("");
      setSelectBloco([]);
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Erro: Não foi possível retirar blocos do grupo",
        severity: "error",
      });
    } finally {
      setLoadingHandleGrupo(false);
      setOpenRemoveModalGrupo(false);
      setBlocoId("");
      setGrupoId("");
      setLoadingRemoveGrupo(false);
    }
  };

  const deleteManyBlocosGrupos = async (blocosId) => {
    setLoadingHandleGrupo(true);
    setLoadingRemoveGrupo(true);
    try {
      const response = await api.post("/interno/deleteMany/rlGrupoBlocosFlow", {
        blocosId: blocosId,
      });

      setBlocos((blocos) =>
        blocos?.map((bloco) =>
          bloco?.id === blocoId
            ? {
                ...bloco,
                RlGruposBlocosFlow: bloco?.RlGruposBlocosFlow?.filter(
                  (grupo) => grupo?.grupoId !== grupoId
                ),
              }
            : bloco
        )
      );

      setBlocos((blocos) =>
        blocos?.map((bloco) => {
          const blocoAtualizado = blocosId?.find((id) => id === bloco?.id);

          if (blocoAtualizado) {
            return {
              ...bloco,
              RlGruposBlocosFlow: [],
            };
          }

          return bloco;
        })
      );

      setSelectBloco([]);
      setSnackbar({
        children: "Blocos retirados do grupo",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Erro: Não foi possível retirar blocos do grupo",
        severity: "error",
      });
    } finally {
      setLoadingHandleGrupo(false);
      setOpenRemoveModalGrupo(false);
      setBlocoId("");
      setGrupoId("");
      handleCloseMenuGrupos();
      setOpenRemoveManyModalGrupo(false);
      setLoadingRemoveGrupo(false);
    }
  };

  const comparaNome = (novoNome) => {
    return blocos.some(
      (bloco) =>
        bloco?.nome?.replace(/\s/g, "") === novoNome?.replace(/\s/g, "")
    );
  };
  const comparaOutrosNomes = (novoNome, nomeAtual) => {
    const filtraBlocos = blocos.filter(
      (bloco) =>
        bloco?.nome?.replace(/\s/g, "") !== nomeAtual?.replace(/\s/g, "")
    );
    return filtraBlocos.some(
      (bloco) =>
        bloco?.nome?.replace(/\s/g, "") === novoNome?.replace(/\s/g, "")
    );
  };

  function handleNewBlocos(newBlocos = []) {
    setBlocos((blocos) => {
      const newArray = [...blocos, ...newBlocos];
      return newArray.flat();
    });
  }
  function handleEditBlocos(blocoEditado) {
    setBlocos((blocos) =>
      blocos.map((bloco) => {
        if (bloco.id === blocoEditado.id) {
          return blocoEditado;
        } else {
          return bloco;
        }
      })
    );
  }
  function handleDeleteBloco(id) {
    setBlocos((blocos) => blocos.filter((bloco) => bloco.id !== id));
  }

  async function handleApiGetBlocos() {
    try {
      setLoadingTable(true);
      const response = await api.post("/interno/findMany/blocos", {
        clienteId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          setBlocos(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
    }
  }

  async function handleApiDeleteBlocos() {
    try {
      setLoadingTable(true);

      const response = await api.post("/interno/delete/blocos", {
        id: selectedRow.id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleDeleteBloco(response.data.id);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);

      setOpenDeleteModal(false);
    }
  }
  const updateStatusManyBlocos = async (status) => {
    setLoadingManyStataus(true);
    try {
      const response = await api.post("/interno/update/statusManyBloco", {
        blocosId: selectBloco,
        status: status,
      });
      Array.isArray(response?.data) &&
        response?.data?.map((blocoResponse) => {
          setBlocos((blocos) =>
            blocos?.map((bloco) =>
              bloco?.id === blocoResponse?.id
                ? { ...bloco, status: blocoResponse.status }
                : bloco
            )
          );
        });
      setSelectBloco([]);

      setSnackbar({
        children: "Status do bloco atualizado",
        severity: "success",
      });
    } catch {
      setSnackbar({
        children: "Erro ao atualizar status do bloco",
        severity: "error",
      });
    } finally {
      handleCloseMenuStatus();
      setLoadingManyStataus(false);
    }
  };

  const findManyGrupos = async () => {
    try {
      const response = await api.post("/interno/findManyGrupoBlocosFlow", {
        clienteId: clienteId,
      });
      if (response.data) {
        setGrupos(response?.data);
      }
    } catch (erro) {
      console.error(erro);
    } finally {
    }
  };

  const criarGrupo = async (grupoName) => {
    let min = Math.ceil(0);
    let max = Math.floor(6);
    let indexColorChip = Math.floor(Math.random() * (max - min)) + min;

    if (grupoName.trim() === "") {
      setSnackbar({
        children: "Necessário informar o nome do grupo",
        severity: "info",
      });
    } else {
      try {
        const response = await api.post("/interno/create/grupoBlocosFlow", {
          clienteId: clienteId,
          blocoName: grupoName?.trim(),
          colorGrupo: colorChip[indexColorChip],
        });

        setSnackbar({
          children: "Grupo criado com sucesso",
          severity: "success",
        });
        setGrupos((grupos) => [...grupos, response?.data]);
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Erro ao criar grupo",
          severity: "error",
        });
      } finally {
      }
    }
  };

  const editarGrupo = async () => {
    if (handleGrupo?.grupoName?.trim()) {
      if (
        grupos?.some(
          (grupo) =>
            grupo.grupoName.trim() === handleGrupo?.grupoName.trim() &&
            grupo?.id !== handleGrupo?.id
        )
      ) {
        setGrupoExist(true);
      } else {
        setGrupoExist(false);
        try {
          const response = await api.post("/interno/edit/grupoBlocosFlow", {
            grupoId: handleGrupo?.id,
            grupoName: handleGrupo?.grupoName?.trim(),
            colorGrupo: handleGrupo?.colorGrupo,
          });
          const newGrupos = grupos?.map((grupo) =>
            grupo?.id === response?.data?.id ? response?.data : grupo
          );
          setGrupos(newGrupos);
          setGruposRow((gruposRow) =>
            gruposRow?.map((grupoRow) =>
              grupoRow?.id === response?.data?.id ? response?.data : grupoRow
            )
          );

          setSnackbar({
            children: "Grupo editado com sucesso",
            severity: "success",
          });
        } catch (error) {
          console.error(error);
          setSnackbar({
            children: "Erro ao editar grupo",
            severity: "error",
          });
        } finally {
          handleClose();
        }
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setGrupoExist(false);
  };

  const handleCloseMenuStatus = () => {
    setAnchorElStatus(null);
  };
  const handleCloseMenuGrupos = () => {
    setAnchorElGrupos(null);
  };
  const handleCloseOptionsGrupo = () => {
    setAnchorElOptionsGrupo(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickMenuStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleClickOptionsGrupo = (event) => {
    setAnchorElOptionsGrupo(event?.currentTarget);
  };
  const handleClickMenuGrupos = (event) => {
    setAnchorElGrupos(event.currentTarget);
  };

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
    },

    {
      field: "blocos",
      headerName: "Blocos",
      flex: 1,
    },

    {
      field: "status",
      headerName: "Status",
      disabled: "true",
      flex: 1,
      renderCell: (params) => {
        const updateStatusBloco = async (e) => {
          setLoadingChecked(true);

          try {
            socket.emit(
              "status_bloco",
              {
                id: params?.row?.id,
                status: e?.target?.checked,
              },
              (response) => {
                if (response?.data) {
                  setBlocos((blocos) =>
                    blocos?.map((bloco) =>
                      bloco?.id === response?.data?.id
                        ? { ...bloco, status: response?.data?.status }
                        : bloco
                    )
                  );
                  setSnackbar({
                    children: "Status do bloco atualizado",
                    severity: "success",
                  });
                } else {
                  setSnackbar({
                    children: "Error: Não foi atualizar status do bloco",
                    severity: "error",
                  });
                }
              }
            );
          } catch (error) {
            console.error(error);
            setSnackbar({
              children: "Error: Não foi atualizar status do bloco",
              severity: "error",
            });
          } finally {
            setLoadingChecked(false);
          }
        };

        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={params?.row?.status}
                onChange={updateStatusBloco}
                disabled={loadingChecked || loadingManyStatus}
              />
            }
            label={`Bloco ${params?.row?.status ? "ativado" : "desativado"}`}
          />
        );
      },
    },
    {
      field: "grupo",
      headerName: "Grupo",
      flex: 1.3,
      sortable: false,
      renderCell: (params) => {
        let idGrupo = params?.row?.RlGruposBlocosFlow?.map(
          (idGrup) => idGrup?.grupoId
        );
        let grupo = grupos?.filter((grupo) =>
          idGrupo?.some((id) => grupo?.id === id)
        );

        let thisRow = params?.row;

        return (
          <>
            <Button
              onClick={(event) => {
                handleClickOptionsGrupo(event);
                setGruposRow(grupo);
                setThisRowGrupo(thisRow);
              }}
              sx={styles.buttonGrupo}
              endIcon={<ArrowDropDownOutlined />}
            >
              <Box sx={styles?.containerDatagridGrupos}>
                {grupo?.length > 0 ? (
                  grupo?.map((grupo) => (
                    <>
                      <Chip
                        size="small"
                        sx={{
                          ...styles.chipGrupo,
                          bgcolor: grupo?.colorGrupo,
                        }}
                        label={grupo?.grupoName}
                      />
                    </>
                  ))
                ) : (
                  <Box sx={styles.containerNoGrupo}>
                    <Typography sx={styles.textNoGrupo}>
                      Adicione ou crie um grupo
                    </Typography>
                  </Box>
                )}
              </Box>
            </Button>
          </>
        );
      },
    },
    {
      field: "createAt",
      headerName: "Adicionado em",
      flex: 1,
      width: 90,
      valueGetter: (params) => {
        return (params && dayjs(params).toDate()) || "";
      },
      renderCell: (params) => {
        return (
          params?.row?.createAt &&
          dayjs(params?.row?.createAt).format("DD/MM/YYYY HH:mm")
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          const thisRow = params.row;

          hanldeUpDateSelectRow(thisRow);
          setOpenEditModal(true);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }
        const onDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const thisRow = params.row;
          setOpenDeleteModal(true);
          setSelectedRow(thisRow);
        };

        return (
          <Box key={params.id}>
            <Tooltip title={"Editar"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Edit color="primary" />
              </IconButton>
            </Tooltip>

            <IconButton onClick={onDelete}>
              <Delete /*  color={"secondary"} */ />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={styles.boxContainer}>
        <Paper
          sx={styles.paperContainer}
          elevation={3}
          variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box sx={styles.containerAcoes}>
              {selectBloco?.length > 0 && (
                <Box sx={styles.containerAcoesButton}>
                  <Button
                    variant="filled"
                    startIcon={<MoreVertOutlined />}
                    onClick={handleClickMenuStatus}
                  >
                    Status
                  </Button>
                  <Button
                    variant="filled"
                    startIcon={<MoreVertOutlined />}
                    onClick={handleClickMenuGrupos}
                  >
                    Grupos
                  </Button>

                  <Menu
                    anchorEl={anchorElStatus}
                    open={openMenuStatus}
                    onClose={handleCloseMenuStatus}
                  >
                    <MenuItem onClick={() => updateStatusManyBlocos(true)}>
                      <ListItemIcon>
                        <CheckCircleOutlineOutlined />
                      </ListItemIcon>
                      <ListItemText>
                        {" "}
                        Ativar bloco
                        {selectBloco?.length > 1 ? "s" : ""}
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => updateStatusManyBlocos(false)}>
                      <ListItemIcon>
                        <DoNotDisturbAltOutlined />
                      </ListItemIcon>
                      <ListItemText>
                        {" "}
                        Desativar bloco
                        {selectBloco?.length > 1 ? "s" : ""}
                      </ListItemText>
                    </MenuItem>
                  </Menu>

                  <Menu
                    anchorEl={anchorElGrupos}
                    open={openMenuGrupos}
                    onClose={handleCloseMenuGrupos}
                  >
                    <Box sx={{ maxHeight: "300px" }}>
                      {grupos?.map((grupo) => (
                        <MenuItem
                          onClick={() =>
                            addManyBlocosGrupos(selectBloco, grupo?.id)
                          }
                        >
                          <ListItemIcon>
                            <Add />
                          </ListItemIcon>
                          <ListItemText>
                            {" "}
                            Adicionar à {grupo?.grupoName}
                          </ListItemText>
                        </MenuItem>
                      ))}
                      <MenuItem
                        onClick={() => setOpenRemoveManyModalGrupo(true)}
                      >
                        <ListItemIcon>
                          <DoNotDisturbAltOutlined />
                        </ListItemIcon>
                        <Typography sx={{ fontWeight: 500 }}>
                          {" "}
                          Remover de todos os grupos
                        </Typography>
                      </MenuItem>
                    </Box>
                  </Menu>
                </Box>
              )}
            </Box>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: "center", vertical: "top" }}
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            >
              <Box>
                <TextField
                  value={handleGrupo?.grupoName}
                  size="small"
                  sx={{ margin: "10px" }}
                  onChange={(e) => {
                    e.stopPropagation();
                    setHandleGrupo((prev) => ({
                      ...prev,
                      grupoName: e.target.value,
                    }));
                  }}
                  error={grupoExist || !handleGrupo?.grupoName?.trim()}
                  helperText={
                    (grupoExist && "Grupo já existe") ||
                    (!handleGrupo?.grupoName?.trim() && "Nome obrigatório")
                  }
                />
              </Box>
              <Box>
                <Divider />
                <Button
                  startIcon={<Delete sx={{ marginLeft: "25px" }} />}
                  sx={{
                    ...styles.buttonAddGrupo,
                    justifyContent: "start",
                    padding: "10px 0",
                  }}
                  onClick={() => setOpenDeleteModalGrupo(true)}
                >
                  Deletar grupo
                </Button>
                <Divider />
              </Box>
              <Typography sx={styles.textNoGrupo}>Cor</Typography>
              <Box>
                {colorChip?.map((color, index) => (
                  <MenuItem
                    onClick={() =>
                      setHandleGrupo((prev) => ({
                        ...prev,
                        colorGrupo: colorChip[index],
                      }))
                    }
                  >
                    <Box
                      sx={{
                        ...styles.colorSelectChip,
                        backgroundColor: color,
                      }}
                    ></Box>
                    <Typography>
                      {index === 0
                        ? "Vermelho"
                        : index === 1
                        ? "Verde"
                        : index === 2
                        ? "Laranja"
                        : index === 3
                        ? "Azul"
                        : index === 4
                        ? "Roxo"
                        : index === 5
                        ? "Cinza"
                        : "Cinza"}
                    </Typography>
                    {handleGrupo.colorGrupo === colorChip[index] && (
                      <ListItemIcon sx={{ marginLeft: "auto" }}>
                        <Check />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                ))}
              </Box>
              <Box sx={styles.containerHandleButtons}>
                <Button
                  onClick={handleClose}
                  size={"small"}
                  sx={styles.buttonHandle}
                >
                  Cancelar
                </Button>
                <Button
                  size={"small"}
                  variant="contained"
                  sx={styles.buttonHandle}
                  onClick={editarGrupo}
                >
                  Salvar
                </Button>
              </Box>
            </Menu>

            <Menu
              id="basic-menu"
              anchorEl={anchorElOptionsGrupo}
              open={openMenuOptionsGrupo}
              onClose={() => {
                handleCloseOptionsGrupo();
                setBuscarGrupo("");
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{ width: "450px" }}
            >
              <Autocomplete
                freeSolo
                size="small"
                inputValue={buscarGrupo}
                onInputChange={(event, value) => setBuscarGrupo(value)}
                clearIcon={null}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  setBuscarGrupo(inputValue);
                  return [];
                }}
                disableCloseOnSelect
                disabled={loadingRemoveGrupo}
                multiple
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                options={[]}
                value={gruposRow ? gruposRow : []}
                getOptionLabel={(option) => option?.grupoName}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    const { key, onDelete, ...tagProps } = getTagProps({
                      index,
                    });

                    return (
                      <Chip
                        sx={{ ...styles.chip, bgcolor: option?.colorGrupo }}
                        key={key}
                        label={option.grupoName}
                        {...tagProps}
                        onDelete={() => {
                          setBlocoId(thisRowGrupo?.id);
                          setGrupoId(option);
                          setOpenRemoveModalGrupo(true);
                        }}
                      />
                    );
                  })
                }
                style={{ width: 280, margin: "0 auto" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      gruposRow?.length !== 0 ? "" : "Adicione ou crie um grupo"
                    }
                  />
                )}
              />

              <Box>
                <Divider sx={{ margin: "10px 0" }} />

                <Typography sx={styles.textNoGrupo}>
                  Selecione uma opção ou crie uma{" "}
                </Typography>
              </Box>
              <Box sx={styles.containerGrupo}>
                {filteredRows?.map((grupo) => (
                  <Box style={{ width: 300 }}>
                    <MenuItem sx={{ cursor: "default" }}>
                      <Checkbox
                        disabled={loadingHandleGrupo}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={gruposRow?.some(
                          (grupoRow) => grupoRow?.id === grupo?.id
                        )}
                        style={{ marginRight: 8 }}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            addGrupoBloco(thisRowGrupo?.id, grupo);
                          } else {
                            deleteBlocosGrupos(thisRowGrupo?.id, grupo);
                          }
                        }}
                      />
                      <Chip
                        size="small"
                        label={grupo?.grupoName}
                        sx={{ ...styles.chipGrupo, bgcolor: grupo?.colorGrupo }}
                      />

                      <IconButton
                        onKeyDown={(event) => {
                          event.stopPropagation();
                        }}
                        onClick={(event) => {
                          handleClick(event);
                          setIdGrupoDelete(grupo?.id);
                          setHandleGrupo(grupo);

                          event.preventDefault();
                        }}
                        sx={{ marginLeft: "auto" }}
                      >
                        <MoreHoriz fontSize="small" />
                      </IconButton>
                    </MenuItem>
                  </Box>
                ))}
              </Box>
              {buscarGrupo &&
                (!filteredRows?.some(
                  (filter) => filter?.grupoName === buscarGrupo
                ) ||
                  filteredRows?.length === 0) && (
                  <MenuItem
                    style={{ width: 300 }}
                    onClick={(e) => {
                      criarGrupo(buscarGrupo);
                      e.stopPropagation();
                    }}
                  >
                    <Typography
                      style={{ ...styles.grupoName, marginLeft: "20px" }}
                    >
                      Criar "{buscarGrupo}"
                    </Typography>
                  </MenuItem>
                )}
            </Menu>

            <Button
              variant="contained"
              disabled={loadingTable}
              startIcon={<Add />}
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              Adicionar
            </Button>
          </Stack>
          <DataGrid
            getRowHeight={() => "auto"}
            onCellKeyDown={(params, event) => event.stopPropagation()}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectBloco(newRowSelectionModel);
            }}
            isRowSelectable={() => !loadingManyStatus}
            rowSelectionModel={selectBloco}
            sx={styles.datagrid}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={blocos}
            getRowId={(row) => row.id}
            columns={columns}
            loading={loadingTable}
            disableColumnMenu
            disableRowSelectionOnClick={true}
          />{" "}
        </Paper>
      </Box>{" "}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <CreateModalBlocos
        setOpenCreateModal={setOpenCreateModal}
        openCreateModal={openCreateModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        setErrorName={setErrorName}
        errorName={errorName}
        comparaNome={comparaNome}
        handleNewBlocos={handleNewBlocos}
        clienteId={clienteId}
      />
      <EditModalBlocos
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        selectedRow={selectedRow}
        setErrorName={setErrorName}
        errorName={errorName}
        comparaOutrosNomes={comparaOutrosNomes}
        handleEditBlocos={handleEditBlocos}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={loadingDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={handleApiDeleteBlocos}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModalGrupo}
        load={loadingHandleGrupo}
        setOpenDeleteModal={setOpenDeleteModalGrupo}
        execute={deleteGrupo}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openRemoveModalGrupo}
        load={loadingRemoveGrupo}
        setOpenDeleteModal={setOpenRemoveModalGrupo}
        execute={() => deleteBlocosGrupos(blocoId, grupoId)}
        severity={"error"}
        contentText={deleteBodyRemoveGrupo}
        confirmText={confirmRemoveGrupo}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openRemoveManyModalGrupo}
        load={loadingRemoveGrupo}
        setOpenDeleteModal={setOpenRemoveManyModalGrupo}
        execute={() => deleteManyBlocosGrupos(selectBloco)}
        severity={"error"}
        contentText={deleteBodyManyGrupos}
        confirmText={confirmRemoveGrupo}
      />
    </>
  );
}
