import { bgcolor, width } from "@mui/system";

const styles = (theme) => ({
  paper: {
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
    padding: "20px",
    justifyContent: "center",
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    minHeight: "76vh",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    maxWidth: "100%",
    minHeight: "76vh",
  },
  percentileView: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  dataGrid: {
    "&. MuiDataGrid-main": {
      overflowY: "scroll",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-selectedRowCount": {
      fontSize: "0px",
    },
  },
});

export default styles;
