import { Box } from "@mui/system";
import { useTheme } from "@mui/system";
import useStyles from "./styles";
import { Paper, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Outlet, useNavigate } from "react-router-dom";
export default function PainelDeLogs() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Define tab atual de acordo com a url
    const pageName = window.location.pathname;
    const pages = ["/interno/auditoria", "/interno/auditoria/acessos"];
    let breakFlag = false;
    for (let index = 0; index < pages.length; index++) {
      if (pageName === pages[index]) {
        setValue(index);
        breakFlag = true;
        break;
      }
    }
    if (breakFlag == false) {
      setValue(0);
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerTabs}>
        <TabContext value={value}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2.6 }}>
            <Box sx={styles.containerTab}>
              <TabList>
                <Tab
                  label="AÇÕES"
                  value={0}
                  onClick={() => {
                    navigate("/interno/auditoria");
                    setValue(0);
                  }}
                />
                <Tab
                  label="ACESSOS"
                  value={1}
                  onClick={() => {
                    navigate("/interno/auditoria/acessos");
                    setValue(1);
                  }}
                />
              </TabList>
            </Box>
            <Paper
              sx={styles.paper}
              variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
              elevation={3}
            >
              <Outlet />
            </Paper>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
}
