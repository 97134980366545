import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
  Alert,
  Snackbar,
} from "@mui/material";
import useStyles from "./styles";
import loginImg from "../../../assets/imgs/login/loginImg.webp";
import logoComputizeDark from "../../../assets/imgs/logos/logocomputizeDark.webp";
import {
  PrivacyTipOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");

  const [snackbar, setSnackbar] = useState(null);
  const [Null, setNull] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [shrink, setShrink] = useState(false);
  const [passShrink, setPassShrink] = useState(false);

  // limita o keydown no campo senha
  const [enterFlag, setEnterFlag] = useState(false);

  const url = `${
    window?.config?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || ""
  }`;

  const styles = useStyles();

  const theme = useTheme();

  const handleLogin = async () => {
    if (!login || !senha) {
      setNull(true);
      return;
    }
    setLoading(!login || !senha);
    try {
      const response = await axios.post(
        `${url}/cliente/login`,
        { login, senha },
        { withCredentials: false }
      );

      if (response?.data?.token) {
        const { perfil, logs, ...userData } = response.data;
        localStorage.clear();
        if (response.data.usuarioCliente?.status) {
          localStorage.setItem("userCliente", JSON.stringify(userData));
          if (perfil)
            localStorage.setItem("perfilCliente", JSON.stringify(perfil));
          localStorage.setItem("logs", JSON.stringify(logs));
        }

        if (
          response.data.usuarioCliente?.status == true &&
          response.data.perfilAlreadyExists === true &&
          response.data.perfil.telefone
        ) {
          navigate("/home");
        } else if (
          response.data.usuarioCliente?.status == true &&
          (response.data.perfilAlreadyExists === false ||
            !response?.data?.perfil?.telefone)
        ) {
          navigate("/cliente/cadastro");
        } else if (
          response.data.usuarioCliente?.status == false &&
          response.data.perfilAlreadyExists === true
        ) {
          setSnackbar({
            children: `Usuário desativado`,
            severity: "error",
          });
        }
      } else {
        localStorage.clear();
        setSnackbar({
          children: `Erro: senha ou login incorreto`,
          severity: "error",
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        setSnackbar({
          children: `Erro: senha ou login incorreto`,
          severity: "error",
        });
      } else if (error?.response?.status === 429) {
        const dataError = isValidJSON(error?.response?.data?.error)
          ? JSON.parse(error?.response?.data?.error)
          : "";
        setSnackbar({
          children: `Erro: Muitas tentativas incorretas. Por favor aguarde ${
            dataError?.waitingTime || 5
          } minutos`,
          severity: "error",
        });
      } else {
        setSnackbar({
          children: `Erro: Falha ao tentar se conectar ao Servidor`,
          severity: "error",
        });
      }
      console.error(error);
    }
    setLoading(false);
  };

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          position: "relative",
          overflow: "hidden",
          minHeight: "800px",
        }}
      >
        <img
          src={loginImg}
          style={{
            position: "absolute",
            width: "100vw",
            minWidth: "1700px",
            minHeight: "891px",
            height: "auto",
            top: -400,
          }}
        />
      </Box>
      <Button
        variant="outlined"
        color="white"
        sx={styles.buttonNavigate}
        onClick={() => {
          navigate("/interno/login");
        }}
        endIcon={<PrivacyTipOutlined />}
      >
        INTERNO
      </Button>
      <Box sx={styles.body}>
        <img src={logoComputizeDark} style={styles.img} alt="logo-computize" />

        <TextField
          InputLabelProps={{ sx: { ml: 4.5 }, shrink }}
          inputProps={{
            sx: { height: "48px", padding: 0 },
          }}
          id="login"
          variant="filled"
          placeholder="Login"
          size="small"
          sx={styles.textfield}
          error={!login && Null}
          onChange={(event) => {
            setLogin(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              document.getElementById("password").focus();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle color="disabled" sx={{ mb: 2 }} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          InputLabelProps={{ sx: { ml: 4.5 }, shrink: passShrink }}
          inputProps={{
            sx: { height: "48px", padding: 0 },
          }}
          id="password"
          variant="filled"
          placeholder="Senha"
          size="small"
          type={showPassword ? "text" : "password"}
          sx={styles.textfield}
          autoComplete="off"
          error={!senha && Null}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !enterFlag) {
              setEnterFlag(true);
              handleLogin();
            }
          }}
          onKeyUp={(event) => {
            setEnterFlag(false);
          }}
          onChange={(event) => {
            setSenha(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color="disabled" sx={{ mb: 2 }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="disabled"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={styles.boxRecover}>
          <Button
            sx={styles.buttonRecover}
            onClick={() => {
              navigate("/cliente/redefinirSenha");
            }}
          >
            Esqueceu a senha ?
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={styles.button}
          onClick={handleLogin}
        >
          ENTRAR
        </Button>
      </Box>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
