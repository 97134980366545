import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Line,
  LineChart,
} from "recharts";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { Box, Typography, useTheme, Stack } from "@mui/material";

import useStyles from "./styles";
import { padding } from "@mui/system";

const LineChartComponent = ({ dataApi, windowWidth }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const calcInterval = () => {
    let mult = windowWidth < 800 ? 3 : 1;
    if (dataApi?.length > 700) {
      return 110 * mult;
    } else if (dataApi?.length > 100) {
      return 25 * mult;
    } else {
      return 3 * mult;
    }
  };

  return (
    <ResponsiveContainer width="100%">
      <LineChart
        data={dataApi}
        margin={{
          top: 20,
          right: 0,
          left: -20,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          style={{ fontSize: "12px", fontWeight: 500 }}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
          tickFormatter={(value) => dayjs(value).format("DD/MM HH:mm")}
          interval={calcInterval()}
        />
        <YAxis
          interval={0}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
          style={{ fontSize: "12px", fontWeight: 500 }}
          domain={[0, 30]}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <Box
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark" ? "#07070752" : "#ffffffb4",
                    padding: "8px",
                  }}
                >
                  <Typography fontSize={"14px"}>{`Data: ${dayjs(label).format(
                    "DD/MM/YYYY HH:mm"
                  )}`}</Typography>

                  <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {payload[0].payload.brbyteTimeRequest && (
                        <Box sx={styles.containerDetails}>
                          <Box
                            sx={{
                              bgcolor:
                                theme.palette.mode === "dark"
                                  ? "#b354b9"
                                  : "#8f2a96",
                              width: "14px",
                              height: "14px",
                              borderRadius: "2px",

                              marginRight: "5px",
                            }}
                          />
                          <Typography
                            fontSize={"14px"}
                          >{`Tempo da requisição: ${
                            payload[0].payload.brbyteTimeRequest || ""
                          }s`}</Typography>
                        </Box>
                      )}
                      {payload[0].payload.wanguardTimeRequest && (
                        <Box sx={styles.containerDetails}>
                          <Box
                            sx={{
                              bgcolor: "#07A2ED",
                              width: "14px",
                              height: "14px",
                              borderRadius: "2px",

                              marginRight: "5px",
                            }}
                          />
                          <Typography
                            fontSize={"14px"}
                          >{`Tempo da requisição: ${
                            payload[0].payload.wanguardTimeRequest || ""
                          }s`}</Typography>
                        </Box>
                      )}
                      {payload[0].payload.zabbixTimeRequest && (
                        <Box sx={styles.containerDetails}>
                          <Box
                            sx={{
                              bgcolor: "#F07E11",
                              width: "14px",
                              height: "14px",
                              borderRadius: "2px",

                              marginRight: "5px",
                            }}
                          />
                          <Typography
                            fontSize={"14px"}
                          >{`Tempo da requisição: ${
                            payload[0].payload.zabbixTimeRequest || ""
                          }s`}</Typography>
                        </Box>
                      )}
                      {payload[0].payload.nexusTimeRequest && (
                        <Box sx={styles.containerDetails}>
                          <Box
                            sx={{
                              bgcolor: "#77a154",
                              width: "14px",
                              height: "14px",
                              borderRadius: "2px",

                              marginRight: "5px",
                            }}
                          />
                          <Typography fontSize={"14px"}>
                            {`Tempo da requisição: ${
                              payload[0].payload.nexusTimeRequest || ""
                            }s`}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Stack>
                </Box>
              );
            }

            return null;
          }}
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="brbyteTimeRequest"
          name="BrByte"
          stroke={theme.palette.mode === "dark" ? "#b354b9" : "#8f2a96"}
          strokeWidth={2}
          fill={theme.palette.mode === "dark" ? "#b354b9" : "#8f2a96"}
          fillOpacity="1"
          stackId={1}
          dot={false}
        />

        <Line
          type="monotone"
          dataKey="wanguardTimeRequest"
          name="Wanguard"
          stroke={"#07A2ED"}
          strokeWidth={2}
          fill="##07A2ED"
          fillOpacity="1"
          stackId={1}
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="zabbixTimeRequest"
          name="Zabbix"
          stroke={"#F07E11"}
          strokeWidth={2}
          fill="#F07E11"
          fillOpacity="1"
          stackId={1}
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="nexusTimeRequest"
          name="Nexus"
          stroke={"#77a154"}
          strokeWidth={2}
          fill="#77a154"
          fillOpacity="1"
          stackId={1}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
