import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import useStyles from "./styles";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

export default function RankingPercentil({
  data = [],
  loading = false,
  setSelectedRow = () => {},
  clientFilter = {},
  selectedMonth = "",
}) {
  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);
  // Funcionamento
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [previousMonth, setPreviousMonth] = useState(false);
  // Carregamento dos dados
  useEffect(() => {
    setRows(data);
    setFilteredRows(data);
  }, [data]);

  const handleRowClick = (params) => {
    setSelectedRow(params?.row);
  };

  // Conversão de dados
  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 B";
    const k = 1000;
    const sizes = ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (isNaN(parseFloat((bytes / Math.pow(k, i)).toFixed(2)))) {
      return "";
    } else {
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    }
  };

  // Filtra os dados a serem exibidor de acordo com o campo buscar
  useEffect(() => {
    if (clientFilter) {
      setFilteredRows(
        rows.filter((row, index) => {
          row.numero = index;

          return row?.percentilCliente?.cliente?.nomeFantasia
            ?.toLowerCase()
            ?.includes(
              clientFilter?.percentilCliente?.cliente?.nomeFantasia?.toLowerCase()
            );
        })
      );
    } else {
      setFilteredRows(rows);
    }
    //}, [searchItem]);
  }, [clientFilter]);

  useEffect(() => {
    setPreviousMonth(Boolean(selectedMonth !== "actual"));
  }, [selectedMonth, clientFilter]);

  //views a serem renderizados nos referentes renderCell
  const percentileView = (params) => {
    const percentile =
      (previousMonth
        ? params?.row?.tempoExcedentePeriodoAnterior / params?.row?.tempoMaximo
        : params?.row?.tempoExcedente / params?.row?.tempoMaximo) *
        100 <
      100
        ? (previousMonth
            ? params?.row?.tempoExcedentePeriodoAnterior /
              params?.row?.tempoMaximo
            : params?.row?.tempoExcedente / params?.row?.tempoMaximo) * 100
        : isNaN(
            (previousMonth
              ? params?.row?.tempoExcedentePeriodoAnterior /
                params?.row?.tempoMaximo
              : params?.row?.tempoExcedente / params?.row?.tempoMaximo) * 100
          )
        ? 0
        : params?.row?.tempoMaximo > 0
        ? 100
        : 0;

    const percentileValue = isNaN(
      (previousMonth
        ? params?.row?.tempoExcedentePeriodoAnterior / params?.row?.tempoMaximo
        : params?.row?.tempoExcedente / params?.row?.tempoMaximo) * 100
    )
      ? 0
      : params?.row?.tempoMaximo > 0
      ? (previousMonth
          ? params?.row?.tempoExcedentePeriodoAnterior /
            params?.row?.tempoMaximo
          : params?.row?.tempoExcedente / params?.row?.tempoMaximo) * 100
      : 0;

    return (
      <Box sx={styles.percentileView}>
        <LinearProgress
          variant="determinate"
          value={percentile}
          sx={{
            width: "100px",
            height: 10,
            borderRadius: 5,
            "&.MuiLinearProgress-root .MuiLinearProgress-bar": {
              bgcolor:
                percentile == 100
                  ? "chip.pending"
                  : percentile >= 75
                  ? "chip.inProgress"
                  : percentile >= 50
                  ? "chip.attention"
                  : "chip.completed",
            },
          }}
        />

        <Typography variant="mySubtitle2">
          {percentileValue?.toFixed(2)}%
        </Typography>
      </Box>
    );
  };

  const commitView = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        {formatBytes(
          params?.row?.percentilCliente?.valorExcedente * 1000000
        ) ? (
          <Typography>
            {formatBytes(
              params?.row?.percentilCliente?.valorExcedente * 1000000
            )}
          </Typography>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>Não encontrado</Typography>
          </Box>
        )}
      </Box>
    );
  };

  const consumoView = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        {formatBytes(
          previousMonth
            ? params?.row?.consumoPercentilPeriodoAnterior
            : params?.row?.consumoPercentil
        ) ? (
          <Typography>
            {formatBytes(
              previousMonth
                ? params?.row?.consumoPercentilPeriodoAnterior
                : params?.row?.consumoPercentil
            )}
          </Typography>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography>Não encontrado</Typography>
          </Box>
        )}
      </Box>
    );
  };

  // Definição das colunas
  const columns = [
    {
      editable: false,
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "cliente",
      headerName: "CLIENTE",
      flex: 1,
      valueGetter: (value, row, params, current) => {
        return row?.percentilCliente?.cliente?.nomeFantasia;
      },
    },
    {
      field: "tempoExcedente",
      headerName: "90TH CONSUMIDO",
      flex: 1,
      renderCell: percentileView,
      valueGetter: (value, row, params, current) => {
        const percentil = previousMonth
          ? row.tempoExcedentePeriodoAnterior
          : row?.tempoExcedente;
        return percentil;
      },
    },
    {
      field: "valorExcedente",
      headerName: "COMMIT",
      flex: 1,
      renderCell: commitView,
    },
    {
      field: "consumoPercentil",
      headerName: "CONSUMO",
      flex: 1,
      renderCell: consumoView,
    },
  ];

  return (
    <Paper
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
      sx={{
        ...styles.boxContainer,
        backgroundColor: theme.palette.mode === "dark" && "transparent",
      }}
    >
      <DataGrid
        onRowClick={handleRowClick}
        rows={filteredRows}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        pageSizeOptions={[25, 50, 100]}
        initialState={{
          pagination: {
            paginationModel: { page: 1, pageSize: 25 },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        columnHeaderHeight={80}
        disableColumnMenu
        sx={{
          ...styles.dataGrid,
        }}
        loading={loading}
        density="compact"
      />
    </Paper>
  );
}
