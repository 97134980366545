import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Clear, OpenInNew, Search } from "@mui/icons-material";
import api from "../../../../../services/api";
import ModalDetails from "./modalDetails";
import useStyles from "./styles";

//dayjs(cobranca?.invoice_date_due).format("DD-MM-YYYY") || "-",
export default function Auditoria() {
  const styles = useStyles();
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [filteredRows, setFilteredRows] = useState({});
  const [searchItem, setSearchItem] = useState("");
  const [initialData, setInitialData] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [openDetalhes, setOpenDetalhes] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Função para receber dados do banco
  async function handleGetLogs() {
    try {
      setInitialLoad(true);
      const response = await api.get("/interno/administracao/logs");

      setFilteredRows(response?.data);
      setRows(response?.data);
    } catch (error) {
    } finally {
      setInitialLoad(false);
    }
  }

  useEffect(() => {
    // Ao carregar a página chama api para receber os logs
    handleGetLogs();

    // Define state com a largura da tela
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    // Adiciona um listener ao evento de redimensionar a tela
    window.addEventListener("resize", handleResize);
    // O evento chama a função que define o valor do state
  }, []);

  // Filtra os dados a serem seguidos de acordo com a busca
  useEffect(() => {
    if (searchItem) {
      setFilteredRows(
        rows.filter((row, index) => {
          row.numero = index;
          const nome =
            row?.user?.nome?.toLowerCase() +
            " " +
            row?.user?.sobrenome?.toLowerCase();

          return (
            row?.action?.toLowerCase()?.includes(searchItem?.toLowerCase()) ||
            row?.session?.enderecoIp
              ?.toLowerCase()
              ?.includes(searchItem?.toLowerCase()) ||
            row?.table?.toLowerCase()?.includes(searchItem?.toLowerCase()) ||
            row?.time?.toLowerCase()?.includes(searchItem?.toLowerCase()) ||
            nome?.includes(searchItem?.toLowerCase())
          );
        })
      );
    } else {
      setFilteredRows(rows);
    }
  }, [searchItem]);

  //views a serem renderizados nos referentes renderCell
  const timeCellView = (params) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          height: "48px",
          flexDirection: "column",
        }}
      >
        <Typography sx={styles.typographCellView}>
          {params?.row?.time.split(" ")[0]}
        </Typography>
        <Typography sx={styles.typographCellView}>
          {params?.row?.time.split(" ")[1]}
        </Typography>
      </Box>
    );
  };

  const userCellView = (params) => {
    return (
      <Box sx={styles.boxCellView}>
        <Typography sx={styles.typographCellView}>
          {params?.row?.user?.nome} {params?.row?.user?.sobrenome}
        </Typography>
      </Box>
    );
  };

  const ipCellView = (params) => {
    return (
      <Box sx={styles.boxCellView}>
        <Typography sx={styles.typographCellView}>
          {params?.row?.session?.enderecoIp}
        </Typography>
      </Box>
    );
  };

  const tableCellView = (params) => {
    return (
      <Box sx={styles.boxCellView}>
        <Typography sx={styles.typographCellView}>
          {params?.row?.table}
        </Typography>
      </Box>
    );
  };

  const actionCellView = (params) => {
    return (
      <Box sx={styles.boxCellView}>
        <Typography sx={styles.typographCellView}>
          {params?.row?.action}
        </Typography>
      </Box>
    );
  };

  const detailCellView = (params) => {
    return (
      <Tooltip title="Detalhes da ação">
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            //setAnchorEl(event.currentTarget);
            setSelectedRow(params.row);
            setOpenDetalhes(!openDetalhes);
          }}
          id="basic-button"
        >
          <OpenInNew mode="view" params={params} />
        </IconButton>
      </Tooltip>
    );
  };

  // A data passada deve estar no padrão: DD/MM/YYYY HH:mm:ss
  function toISOFormat(dateTimeString) {
    const [date, time] = dateTimeString.split(" ");
    // Divide data e tempo
    const [DD, MM, YYYY] = date.split("/");
    const [HH, mm, ss] = time.split(":");
    // Retorna a data compatível com ISO:
    return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}`;
  }

  // Definição de colunas
  const columns = [
    {
      editable: false,
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "time",
      headerName: "Hora",
      flex: 0.8,
      type: "dataTime",
      valueGetter: (params) => {
        return (params && toISOFormat(params)) || "";
      },
      renderCell: timeCellView,
    },
    {
      field: "user",
      headerName: "Usuário",
      flex: 1,
      renderCell: userCellView,
    },
    {
      field: "ip",
      headerName: "IP",
      flex: 1,
      renderCell: ipCellView,
    },
    {
      field: "table",
      headerName: "Recurso",
      flex: 0.8,
      renderCell: tableCellView,
    },
    {
      field: "action",
      headerName: "Ação",
      flex: 0.8,
      renderCell: actionCellView,
    },
    {
      field: "detail",
      headerName: "Detalhes",
      //flex: 1,
      width: 90,
      renderCell: detailCellView,
      sortable: false,
    },
  ];

  // Variáveis para o carregamento mobile
  const PER_PAGE = 10;
  const count = Math.ceil(filteredRows.length / PER_PAGE);
  const _DATA = usePagination(filteredRows, PER_PAGE);
  const [page, setPage] = useState(1);

  // Pagination para o mobile
  function usePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    function currentData() {
      const begin = (currentPage - 1) * itemsPerPage;
      const end = begin + itemsPerPage;
      return data.slice(begin, end);
    }

    function next() {
      setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
      setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
      const pageNumber = Math.max(1, page);
      setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
  }

  // Fução para alterar a página no pagination do mobile
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <Box sx={styles.boxContainer}>
      {/* Barra de Busca */}
      <Box>
        <TextField
          variant="filled"
          label="Buscar"
          placeholder="Hora, Nome, Ip, etc..."
          value={searchItem}
          size="small"
          onChange={(event) => {
            setSearchItem(event.target.value);
          }}
          sx={styles.searchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchItem ? (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchItem("")} edge="end">
                  <Clear />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
      </Box>
      {windowWidth <= 760 ? (
        // Exibição Mobile
        <Box>
          <Divider />
          {Array.isArray(filteredRows) &&
            _DATA.currentData().map((row, index) => (
              <Box key={index}>
                <ListItem sx={styles.mobile.listItem}>
                  <Box sx={styles.mobile.content}>
                    <Box sx={styles.mobile.contentRow}>
                      <Box>
                        <Typography
                          sx={styles.mobile.typography}
                          variant="caption"
                        >
                          Hora:{" "}
                        </Typography>
                        <Typography variant="caption">{row?.time}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={styles.mobile.typography}
                          variant="caption"
                        >
                          Usuário:{" "}
                        </Typography>
                        <Typography variant="caption">
                          {row?.user?.nome + " " + row?.user?.sobrenome}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.mobile.contentRow}>
                      <Box>
                        <Typography
                          sx={styles.mobile.typography}
                          variant="caption"
                        >
                          IP:{" "}
                        </Typography>
                        <Typography variant="caption">
                          {row?.session?.enderecoIp}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={styles.mobile.typography}
                          variant="caption"
                        >
                          Recurso:{" "}
                        </Typography>
                        <Typography variant="caption">{row?.table}</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        sx={styles.mobile.typography}
                        variant="caption"
                      >
                        Ação:{" "}
                      </Typography>
                      <Typography variant="caption">{row?.action}</Typography>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={(event) => {
                      event.preventDefault();
                      //setAnchorEl(event.currentTarget);
                      setSelectedRow(row);
                      setOpenDetalhes(!openDetalhes);
                    }}
                    id="basic-button"
                  >
                    <OpenInNew mode="view" />
                  </IconButton>
                </ListItem>
                <Divider />
              </Box>
            ))}
          <Box sx={{ display: "flex", marginTop: "10px" }}>
            <Stack sx={{ margin: "auto" }} spacing={2}>
              <Pagination
                count={count}
                page={page}
                onChange={handleChangePage}
              />
            </Stack>
          </Box>
        </Box>
      ) : (
        // Exibição Desk
        <Box>
          <Box sx={styles.boxDataGrid}>
            <DataGrid
              loading={initialLoad}
              rows={filteredRows}
              columns={columns}
              autoHeight={true}
              getRowHeight={() => "auto"}
              autosizeOptions={{ includeOutliers: true }}
              pageSizeOptions={[25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
                sorting: {
                  sortModel: [{ field: "time", sort: "desc" }],
                },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              sx={styles.dataGrid}
              density="compact"
              columnHeaderHeight={80}
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </Box>
        </Box>
      )}
      <ModalDetails
        openDetalhes={openDetalhes}
        setOpenDetalhes={setOpenDetalhes}
        selectedRow={selectedRow}
      />
    </Box>
  );
}
