import { Container } from "@mui/system";

const styles = (theme) => ({
  container: { display: "flex", flexDirection: "column", gap: 2 },
  header: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },
  filterBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
  },
  filterTypograph: { textWrap: "nowrap" },
  formControl: {
    display: "flex",
    width: "130px",
    minWidth: "130px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "primary",
      color: "primary",
    },
    "& .MuiInputLabel-root": {
      color: "primary",
    },
    svg: {
      color: "primary",
    },
    input: {
      color: "primary",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    mt: "15px",
    mr: 0,
    gap: 4,
    maxWidth: "100vw",
    "@media (max-width: 1100px)": {
      flexDirection: "column",
    },
  },
  ranking: {
    display: "flex",
    width: "45%",
    maxHeight: "82.5vh",
    "@media (max-width: 1100px)": {
      width: "100%",
    },
  },
  status: {
    display: "flex",
    width: "55%",
    "@media (max-width: 1100px)": {
      width: "100%",
    },
  },
});

export default styles;
