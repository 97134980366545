import {
  Alert,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useStyles from "./styles";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Add, Remove, Clear, Search } from "@mui/icons-material";
import api from "../../../../../services/api";
import { useEffect, useState } from "react";
export default function Blocos() {
  const [search, setSearch] = useState("");
  const [searchAdd, setSearchAdd] = useState("");
  const [blocosAdicionados, setBlocosAdicionados] = useState([]);
  const [blocos, setBlocos] = useState([]);
  const [selectRows, setSelectRows] = useState([]);
  const [selectRowsAdd, setSelectRowsAdd] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    getBlocos();
  }, []);

  const getBlocos = async () => {
    setLoading(true);
    try {
      const response = await api.get("/interno/blocosEasyFlow");

      setBlocos(response?.data?.blocosDisponiveis);
      setBlocosAdicionados(response?.data?.blocosAdicionados);
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Erro: Não foi possível receber blocos",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddBlocos = async () => {
    setEdit(true);
    const blocosAdd = blocos?.filter((bloco) =>
      selectRows?.includes(bloco?.id)
    );
    const blocosDisponiveis = blocos?.filter(
      (bloco) => !selectRows?.includes(bloco?.id)
    );
    try {
      const response = await api.post("/interno/createBlocos", {
        blocos: blocosAdd,
      });

      setBlocosAdicionados((prev) => [...prev, ...response?.data]);
      setBlocos(blocosDisponiveis);
      setSnackbar({
        children: `Blocos adicionados ao EasyFlow`,
        severity: "success",
      });
      setSelectRows([]);
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Não foi possível adicionar blocos",
        severity: "error",
      });
    } finally {
      setEdit(false);
    }
  };

  const handleRemoveBlocos = async () => {
    setEdit(true);
    const blocosRemovidos = blocosAdicionados?.filter((bloco) =>
      selectRowsAdd?.includes(bloco?.id)
    );
    const blocosAdd = blocosAdicionados?.filter(
      (bloco) => !selectRowsAdd?.includes(bloco?.id)
    );
    try {
      const response = await api.post("/interno/deleteBlocos", {
        blocos: blocosRemovidos,
      });
      setBlocos((prev) => [...prev, ...blocosRemovidos]);
      setBlocosAdicionados(blocosAdd);
      setSelectRowsAdd([]);
      setSnackbar({
        children: "Blocos removidos do EasyFlow",
        severity: "info",
      });
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Erro ao remover blocos",
        severity: "error",
      });
    } finally {
      setEdit(false);
    }
  };
  const filteredRows = blocos?.filter((row, index) => {
    row.numero = index;

    return (
      row?.nome?.toLowerCase()?.includes(search?.toLowerCase()) ||
      row?.blocos?.toLowerCase()?.includes(search?.toLowerCase()) ||
      row?.cliente?.toLowerCase()?.includes(search?.toLowerCase())
    );
  });

  const filteredRowsAdd = blocosAdicionados?.filter((row, index) => {
    row.numero = index;

    return (
      row?.nome?.toLowerCase()?.includes(searchAdd?.toLowerCase()) ||
      row?.blocos?.toLowerCase()?.includes(searchAdd?.toLowerCase()) ||
      row?.cliente?.toLowerCase()?.includes(searchAdd?.toLowerCase())
    );
  });
  const columnsDisponiveis = [
    {
      field: "blocos",
      headerName: "Bloco",
      width: 150,
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 150,
      flex: 1,
    },
    {
      field: "cliente",
      headerName: "Cliente",
      width: 110,
      flex: 1,
    },
    {
      field: "clienteId",
      headerName: "Status cliente",
      width: 110,
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.clienteId ? (
              <Chip
                label={"Cadastrado"}
                sx={{
                  ...styles.chip,
                  bgcolor: "chip.completed",
                  color: "#ffffff",
                }}
              />
            ) : (
              <Chip
                label={"Não cadastrado"}
                sx={{
                  ...styles.chip,
                  bgcolor: "chip.pending",
                  color: "#ffffff",
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  const columnsAdicionados = [
    {
      field: "blocos",
      headerName: "Bloco",
      width: 150,
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 150,
      flex: 1,
    },
    {
      field: "cliente",
      headerName: "Cliente",
      width: 110,
      flex: 1,
    },
  ];

  return (
    <Box sx={styles.boxContainer}>
      <Paper variant={"outlined"} sx={styles.paperContainer}>
        <Typography variant="mySubtitle">Blocos disponíveis</Typography>
        <Box sx={styles.containerTitulo}>
          <TextField
            autoComplete={false}
            variant="filled"
            label="Buscar blocos"
            value={search}
            size="small"
            placeholder="Bloco, Nome, Cliente, etc..."
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: search ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch("")} edge="end">
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <Button
            variant="contained"
            disabled={(selectRows?.length === 0 && true) || edit}
            startIcon={<Add />}
            onClick={handleAddBlocos}
          >
            {Array.isArray(selectRows) && selectRows?.length === 1
              ? `Adicionar 1 bloco`
              : selectRows?.length > 1
              ? `Adicionar ${selectRows?.length} blocos`
              : "Adicionar bloco"}
          </Button>
        </Box>

        <DataGrid
          rows={filteredRows}
          columns={columnsDisponiveis}
          rowsPerPageOptions={[10, 25, 50]}
          autoHeight={true}
          pageSizeOptions={[10, 25, 50]}
          keepNonExistentRowsSelected
          hideFooterSelectedRowCount={true}
          isRowSelectable={(params) => params.row.clienteId}
          initialState={{
            pagination: {
              paginationModel: { page: 1, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: "clienteId", sort: "desc" }],
            },
          }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectRows(newRowSelectionModel);
          }}
          rowSelectionModel={selectRows}
          disableRowSelectionOnClick
          sx={styles.datagrid}
          disableColumnMenu
          checkboxSelection
          hideFooterPagination={false}
          loading={loading}
        />
      </Paper>

      <Paper variant={"outlined"} sx={styles.paperContainer}>
        <Typography variant="mySubtitle">
          Blocos adicionados ao EasyFlow
        </Typography>
        <Box sx={styles.containerTitulo}>
          <TextField
            autoComplete={false}
            variant="filled"
            label="Buscar blocos"
            value={searchAdd}
            size="small"
            placeholder="Bloco, Nome, Cliente, etc..."
            onChange={(event) => {
              setSearchAdd(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchAdd ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchAdd("")} edge="end">
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
          <Button
            variant="contained"
            /* disabled={loadingTable} */
            disabled={(selectRowsAdd?.length === 0 && true) || edit}
            startIcon={<Remove />}
            onClick={handleRemoveBlocos}
          >
            {Array.isArray(selectRowsAdd) && selectRowsAdd?.length === 1
              ? `Remover 1 bloco`
              : selectRowsAdd?.length > 1
              ? `Remover ${selectRowsAdd?.length} blocos`
              : "Remover bloco"}
          </Button>
        </Box>

        <DataGrid
          rows={filteredRowsAdd}
          columns={columnsAdicionados}
          rowsPerPageOptions={[10, 25, 50]}
          autoHeight={true}
          pageSizeOptions={[10, 25, 50]}
          keepNonExistentRowsSelected
          hideFooterSelectedRowCount={true}
          initialState={{
            pagination: {
              paginationModel: { page: 1, pageSize: 10 },
            },
          }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectRowsAdd(newRowSelectionModel);
          }}
          rowSelectionModel={selectRowsAdd}
          disableRowSelectionOnClick
          sx={styles.datagrid}
          disableColumnMenu
          checkboxSelection
          loading={loading}
        />
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
