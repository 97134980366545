import { DataGrid, gridClasses } from "@mui/x-data-grid";
import clsx from "clsx";
import { Box, Rating, Typography, useTheme } from "@mui/material";

import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

const timeZone = "America/Sao_Paulo";

export default function RecentEventsTable({
  rows = [],

  hideFooter = false,
  load,
}) {
  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 bps";
    const k = 1000;
    const sizes = ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const formatPackge = (bytes) => {
    if (bytes === 0) return "0 pps";
    const k = 1000;
    const sizes = ["pps", "Kpps", "Mpps", "Gbps", "Tpps", "Ppps", "Epps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  const theme = useTheme();

  const columnsRecentEvetns = [
    { field: "id", headerName: "ID", width: 100 },

    {
      field: "prefix",
      headerName: "Prefixo",
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 150,
    },

    {
      field: "link_severity",
      headerName: "Severidade",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 100,
      renderCell: (params) => {
        const thisRow = params.row;
        return (
          <Rating
            name="read-only"
            value={parseInt(thisRow?.link_severity / 10) || 0}
            max={10}
            icon={
              <Box
                sx={{
                  width: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "8px",
                    height: "16px",

                    bgcolor: "secondary.main",
                  }}
                />
              </Box>
            }
            emptyIcon={
              <Box
                sx={{
                  width: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "8px",
                    height: "16px",
                    borderColor: "#73777D",
                    border: "solid 1px",
                  }}
                />
              </Box>
            }
            readOnly
          />
        );
      },
    },
    {
      field: "dataInicial",
      headerName: "Data Inicial",

      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 170,

      renderCell: (params) => {
        const thisRow = params.row;

        return `${
          thisRow?.from?.unixtime
            ? dayjs
                .unix(thisRow?.from?.unixtime)
                .tz(timeZone)
                .format("DD/MM/YYYY HH:mm:ss")
            : ""
        }`;
      },
    },
    {
      field: "dataFinal",
      headerName: "Data Final",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 170,
      renderCell: (params) => {
        const thisRow = params.row;
        return `${
          thisRow?.until?.unixtime
            ? dayjs
                .unix(thisRow?.until?.unixtime)
                .tz(timeZone)
                .format("DD/MM/YYYY HH:mm:ss")
            : "Ongoing"
        }`;
      },
    },
    {
      field: "duration",
      headerName: "Duração",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 100,
      renderCell: (params) => {
        const duration = parseInt(params.row?.duration, 10);
        if (isNaN(duration)) {
          return "Ongoing"; // Retorna um valor padrão se a duração não for um número válido
        }
        if (duration < 60) {
          return `${duration}s`;
        } else if (duration < 3600) {
          const minutes = Math.floor(duration / 60);
          const seconds = duration % 60;
          return `${minutes}m ${seconds}s`;
        } else {
          const hours = Math.floor(duration / 3600);
          const minutes = Math.floor((duration % 3600) / 60);
          return `${hours}h ${minutes}m`;
        }
      },
    },
    {
      field: "impact",
      headerName: "Impacto (bps)",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 100,
      renderCell: (params) => {
        const thisRow = params.row;
        return formatBytes(thisRow?.impact);
      },
    },
    {
      field: "impactPps",
      headerName: "Impacto (pps)",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 100,
      renderCell: (params) => {
        const thisRow = params.row;
        return formatPackge(thisRow?.impactPps);
      },
    },
    {
      field: "anomaly",
      headerName: "Descrição",
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 110,
      renderCell: (params) => {
        const thisRow = params.row;
        const lines = thisRow?.anomaly?.split(",");
        return (
          Array.isArray(lines) &&
          lines?.map((item, index) => (
            <Typography key={index} noWrap>
              {item?.trim()}
            </Typography>
          ))
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columnsRecentEvetns}
        autoHeight={true}
        getRowHeight={() => "auto"}
        autosizeOptions={{ includeOutliers: true }}
        pageSizeOptions={[10, 50, 100]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        loading={load}
        // getRowHeight={() => "auto"}
        sx={{
          display: "grid",
          "&.MuiDataGrid-root": { borderStyle: "none" },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "5px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
          '&.MuiDataGrid-root .MuiDataGrid-container--top [role="row"], & .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"]':
            {
              backgroundColor: "background.paper",
            },
          "& .super-app.positivo_ataque": {
            fontWeight: "600",
            animation: `${
              theme.palette.mode === "dark" ? "myEffectDark" : "myEffectLight"
            } 2.5s infinite`,
          },
          "@keyframes myEffectDark": {
            "0%, 100%": { color: "#FA541C", fontWeight: "600" },
            "50%": { color: "#ffffff", fontWeight: "600" },
          },
          "@keyframes myEffectLight": {
            "0%, 100%": { color: "#FA541C", fontWeight: "600" },
            "50%": { color: "#000000", fontWeight: "600" },
          },
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: "none",
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: "none",
            },
        }}
        density="compact"
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter={hideFooter}
      />
    </>
  );
}
