import { Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import useStyles from "./styles";
import LineChartComponent from "./percentil";
import LineChart from "../../../../../../components/dashboard/charts/line";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";

export default function GraficosResumo({
  networkSummary = {},
  loadingCharts = false,
  selectedRow = {},
}) {
  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);

  // Escalas de exibição
  const maxValuePercentil = Math.max(
    ...(networkSummary?.history?.map((d) => d?.value || 0) || [0])
  );
  const maxIndividualClean = Math.max(
    ...(networkSummary.zabbixHistory?.map((d) => d?.value || 0) || [0])
  );

  const maxIndividualBot = Math.max(
    ...(networkSummary[`${"mitigation_bps"}`]?.bot?.map(
      (d) => d?.value || 0
    ) || [0])
  );

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paper}>
        <Box sx={styles.boxChart}>
          <Typography sx={{ ...styles.cardSubText }}>Percentil</Typography>
          <LineChartComponent
            type={"percentil"}
            percentile95={selectedRow?.consumoPercentil}
            maxValuebps={maxValuePercentil}
            data={
              networkSummary?.history?.map((clean, index) => {
                let result = {};
                (result = {
                  date: clean?.clock,
                  cleanTraffic: clean?.value,
                }) || (result = { date: 0, cleanTraffic: 0 });

                return result;
              }) || []
            }
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {selectedRow?.consumoPercentil > 0 &&
              selectedRow?.percentilCliente?.percentil && (
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "theme.palette.primary.main",
                    height: "2px",
                    transform: "translateY(-12px)",
                  }}
                >
                  {
                    <RemoveIcon
                      sx={{ color: "red", transform: "translateY(6px)" }}
                    />
                  }
                  {selectedRow?.percentilCliente?.percentil}th percentil
                </Typography>
              )}
          </Box>
        </Box>
      </Paper>

      <Paper sx={styles.paper}>
        <Box sx={styles.boxChart}>
          <Typography sx={{ ...styles.cardSubText, color: "chip.completed" }}>
            Tráfego Limpo
          </Typography>
          <LineChartComponent
            type={"limpo"}
            maxValuebps={maxIndividualClean}
            data={
              networkSummary?.zabbixHistory?.map((clean, index) => {
                let result = {};
                (result = {
                  date: clean?.clock,
                  cleanTraffic: clean?.value,
                }) || (result = { date: 0, cleanTraffic: 0 });

                return result;
              }) || []
            }
          />
        </Box>
      </Paper>
    </Box>
  );
}
