import { Avatar, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./styles";
import dayjs from "dayjs";

export default function ModalDetails(params) {
  const styles = useStyles();
  const {
    openDetalhes = false,
    setOpenDetalhes = () => {},
    selectedRow = {},
  } = params;

  return (
    <Modal
      open={openDetalhes}
      onClose={() => {
        setOpenDetalhes(false);
      }}
      sx={styles.modal}
    >
      <Paper sx={styles.paper}>
        {/* informações do usuario */}
        <Box sx={styles.header}>
          <Avatar src={selectedRow?.user?.foto || ""} sx={styles.avatar} />
          <Box sx={styles.headerContent}>
            <Typography variant="title">
              {selectedRow?.user?.nome} {selectedRow?.user?.sobrenome}
            </Typography>
            <Box sx={styles.headerLine}>
              <Box sx={styles.headerItem}>
                <Typography variant="caption" sx={styles.headerSubtitle}>
                  Navegador
                </Typography>
                <Typography sx={styles.headerText}>
                  {selectedRow?.navegador}
                </Typography>
              </Box>
              <Box sx={styles.headerItem}>
                <Typography variant="caption" sx={styles.headerSubtitle}>
                  Sistema
                </Typography>
                <Typography sx={styles.headerText}>
                  {selectedRow?.sistemaOperacional}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.headerLine}>
              <Box sx={styles.headerItem}>
                <Typography variant="caption" sx={styles.headerSubtitle}>
                  IP
                </Typography>
                <Typography sx={styles.headerText}>
                  {selectedRow?.enderecoIp}
                </Typography>
              </Box>
              {selectedRow?.cliente ? (
                <Box sx={styles.headerItem}>
                  <Typography variant="caption" sx={styles.headerSubtitle}>
                    Cliente
                  </Typography>
                  <Typography sx={styles.headerText}>
                    {selectedRow?.cliente?.nomeFantasia}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={styles.headerItem}>
              <Typography variant="caption" sx={styles.headerSubtitle}>
                Hora
              </Typography>
              <Typography sx={styles.headerText}>
                {dayjs(selectedRow?.createAt).format("DD/MM/YYYY hh:mm:ss")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}
