import {
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  ListItem,
  InputAdornment,
  Divider,
  List,
  Pagination,
  Snackbar,
  Alert,
  useTheme,
  Badge,
  Popper,
  Fade,
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  ListItemButton,
  Autocomplete,
  Breadcrumbs,
  Link,
} from "@mui/material";

import { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Add,
  Search,
  Clear,
  SettingsOutlined,
  MoreVert,
  PlayArrow,
  Edit,
  Delete,
  PersonOutline,
  Person,
  NotificationsOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ModalEditCadastro from "../../../components/modalEditCadastro";
import InternoModalDeleteCliente from "../../../components/internoModalDeleteCliente/index";
import { DataGrid } from "@mui/x-data-grid";
import CadastroCliente from "../forms/cadastro";
import api from "../../../services/api";
import { param } from "jquery";
import { boolean, tuple } from "zod";
import { socketInterno as socket } from "../../../services/socket";
function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default function Home() {
  const [snackbar, setSnackbar] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorNotificacao, setAnchorNotificacao] = useState(null);
  const openNotificacao = Boolean(anchorNotificacao);
  const [notificacoes, setNotificacoes] = useState([]);
  const [pendentes, setPendentes] = useState([]);
  const [prefixoSocket, setPrefixoSocket] = useState([]);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [page, setPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [newNotification, setNewNotification] = useState([]);
  const logs = JSON.parse(localStorage.getItem("logs"));

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }

    socket.connect();
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", connectErrorEvent);
    socket.on("update_prefix", (data) => {
      setPrefixoSocket(data);
    });
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    return () => {
      socket.off("connect", onConnect);

      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    setNotificacoes(
      notificacoes
        .filter((notifi) => notifi.clienteId !== prefixoSocket.clienteId)
        .concat(prefixoSocket?.prefixos)
    );
  }, [prefixoSocket]);

  // conteudo a ser passado para o modal delete
  // tipos de modal detele 1: error, 2: warn, 3: info, 4: success
  const deleteBody =
    "Se você prosseguir com a operação, o item e todas as informações associadas serão excluídas permanentemente!";
  const confirmDelete = "EXCLUIR";

  const [buscarUsuario, setBuscarUsuario] = useState("");

  const theme = useTheme();

  const status = {
    0: "pendente",
    1: "completo",
    2: "Em progresso",
    3: "Ag. Revisão",
    4: "Ag. Retorno",
    5: "Arquivado",
  };
  const filteredRows = rows?.filter((row, index) => {
    row.numero = index;

    const statusNome = status[row.status]?.toLowerCase();

    return (
      row.nomeFantasia?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      row.as?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      statusNome?.includes(buscarUsuario) ||
      row.ClientesCadastro?.email
        ?.toLowerCase()
        ?.includes(buscarUsuario?.toLowerCase())
    );
  });

  useEffect(() => {
    handleApiGetClientes();
    handleApiGetSolicitacoes();
  }, []);

  async function handleApiGetClientes() {
    try {
      const response = await api.get("/cliente/home/getClient");

      setRows(response.data);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    }
  }

  async function handleApiGetSolicitacoes() {
    try {
      const response = await api.get("/interno/find/solicitacoesPententes");

      setNotificacoes(response?.data);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    }
  }

  const navigate = useNavigate();

  function removeRow(id) {
    let newArray = [...rows];
    newArray = newArray.filter((row) => row.id !== id);
    setRows(newArray);
  }
  async function handleApiDeleteClientes(id) {
    setDeleteLoad(true);

    try {
      await api.delete("/cliente/dashHome/deleteClient", {
        data: {
          id: id,
          usuarioId: logs?.usuarioId,
          logsLoginId: logs?.id,
        },
      });

      setSnackbar({
        children: "Cliente deletado com sucesso!",
        severity: "success",
      });

      setAnchorEl(null);
      removeRow(id);
      setOpenDeleteModal(false);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setDeleteLoad(false);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (selectedRow) => {
    setAnchorEl(null);
    setAnchorNotificacao(null);
  };

  const PER_PAGE = 10;

  const count = Math.ceil(rows.length / PER_PAGE);
  const _DATA = usePagination(rows, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  //console.log("paginas", rows.length / 10);
  /*   const filteredRows = rows.filter(
    (row) =>
      row.cliente?.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.asn?.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.email?.toLowerCase().includes(filterValue.toLowerCase())
  );
 */
  // TODO: Update the filtered rows in the data grid

  /*<TextField
    label={"Pesquisar"}
    variant="filled"
    placeholder="Nome, Email, ASN, etc..."
    autoComplete="off"
    focused
    sx={{ bgcolor: "red" }}
    onChange={(event) => {
      setFilterValue(event.target.value);
    }}
  />;*/

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  const styles = useStyles(theme);

  /*const getClients = async () => {
    try {
      const response = await axios.get(
        "http://localhost:3333/cliente/dashHome/getClient"
      );
    } catch {}
  };

  useEffect(() => {
    getClients();
  });*/

  function handleNewClient(newClient) {
    let newArray = [...rows];
    newArray.push(newClient);
    setRows(newArray);
  }

  function handleUpdatedClient(upDatedClient) {
    let newArray = rows.map((objeto) => {
      if (objeto.id === upDatedClient.id) {
        return upDatedClient;
      }
      return objeto;
    });
    setRows(newArray);
  }

  const columns = [
    { field: "id", headerName: "ID", width: 100, hide: true },
    {
      field: "nomeFantasia",
      headerName: "Clientes",
      flex: 1,
    },
    {
      field: "as",
      headerName: "ASN",
      width: 150,
    },
    {
      field: "ClientesCadastro",
      headerName: "Email",
      width: 250,
      valueGetter: (ClientesCadastro) => ClientesCadastro?.email,
    },

    {
      field: "ativacaoStatus",
      headerName: "Formulário de Ativação",
      width: 220,
      renderCell: (params) => {
        const thisRow = params.row;
        return (
          <Chip
            label={
              thisRow.status === 0
                ? "Pendente"
                : thisRow.status === 1
                ? "Completo"
                : thisRow.status === 2
                ? "Em progresso"
                : thisRow.status === 3
                ? "Ag. Revisão"
                : thisRow.status === 4
                ? "Ag. Retorno"
                : thisRow.status === 5
                ? "Arquivado"
                : "Pendente"
            }
            sx={{
              bgcolor:
                thisRow.status === 0
                  ? "chip.pending"
                  : thisRow.status === 1
                  ? "chip.completed"
                  : thisRow.status === 2
                  ? "chip.inProgress"
                  : thisRow.status === 3
                  ? "chip.waitingReview"
                  : thisRow.status === 4
                  ? "chip.waitingReturn"
                  : thisRow.status === 5
                  ? "chip.arquive"
                  : "chip.pending",
              width: "100px",
              color: "#ffffff",
              fontWeight: 600,
            }}
            size="small"
          />
        );
      },
    },
    {
      field: "notification",
      headerName: "Solicitações",
      width: 130,

      renderCell: (params) => {
        const onClickNotificacoes = (e) => {
          e.stopPropagation();
          const thisRow = params.row;
          setSelectedRow(thisRow);
          setAnchorNotificacao(e.currentTarget);
          setPendentes(
            notificacoes?.filter((item) => item?.clienteId === params?.id)
          );
        };
        const pending = notificacoes?.filter(
          (item) => item?.clienteId === params?.id
        );

        params.row.notification = pending?.length;
        return (
          <Tooltip title={`Solicitações Pendentes ${pending?.length}`}>
            <IconButton onClick={onClickNotificacoes}>
              <Badge badgeContent={pending?.length} color="primary">
                <NotificationsOutlined />
              </Badge>
            </IconButton>
          </Tooltip>
        );
      },
    },

    {
      field: "actions",
      headerName: "Opções",
      sortable: false,
      width: 80,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          // don't select this row after clicking
          // console.log(params);
          const thisRow = params.row;
          setSelectedRow(thisRow);
          setAnchorEl(e.currentTarget);
        };

        //
        return (
          <Box key={params.id}>
            <Tooltip title={"Ações"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <SettingsOutlined color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Container sx={styles.container}>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/material-ui/getting-started/installation/"
        >
          Perfil
        </Link>
      </Breadcrumbs> */}
      {/*<Stack justifyContent={"center"} direction={"row"}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          allowScrollButtonsMobile
        >
          <Tab
            label="Clientes"
            value={0}
            sx={{
              fontSize: "14px",
              "@media(max-width:640px)": { fontSize: "12px" },
            }}
          />
        </Tabs>
      </Stack>*/}
      <Paper
        sx={styles.paperContent}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        elevation={3}
      >
        <Box sx={styles.boxHeader}>
          <Typography variant="mySubtitle">Clientes</Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={2}
            width={"100%"}
          >
            <TextField
              label="Buscar Usuário"
              variant="filled"
              autoComplete="off"
              placeholder="Nome, Email, Asn, etc..."
              value={buscarUsuario}
              size="small"
              onChange={(event) => {
                setBuscarUsuario(event.target.value);
              }}
              sx={{ width: "280px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: buscarUsuario ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setBuscarUsuario("")} edge="end">
                      <Clear size={"small"} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />

            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => {
                setOpenModal(true);
              }}
              size="large"
            >
              NOVO CLIENTE
            </Button>
          </Stack>
        </Box>
        <Box sx={styles.boxDataGrid}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose} disabled>
              <PlayArrow sx={{ marginRight: "16px" }}></PlayArrow> Adicionar ao
              Juniper
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenEditModal(true);
                setAnchorEl(null);
              }}
            >
              <Edit sx={{ marginRight: "16px" }}></Edit> Editar
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate(
                  `/interno/home/perfil/${selectedRow.id}`
                  // , {state: { selectedRow },}
                );
              }}
            >
              <Person sx={{ marginRight: "16px" }}></Person> Perfil
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                setAnchorEl(null);
              }}
            >
              <Delete sx={{ marginRight: "16px" }}></Delete> Deletar
            </MenuItem>
          </Menu>
          <ClickAwayListener onClickAway={handleClose}>
            <Popper
              open={openNotificacao}
              anchorEl={anchorNotificacao}
              placement={"bottom-end"}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper sx={styles.notificacoesPaper}>
                    <Box sx={styles.notificacoesBox}>
                      {Array.isArray(pendentes) &&
                        pendentes?.map((prefixo, index) => (
                          <Box key={`${prefixo?.id}-${index}`}>
                            {/* <Typography>{prefixo?.prefixo}</Typography> */}
                            <TextField
                              id={"prefixos"}
                              name="prefixos"
                              label="Prefixo"
                              variant="filled"
                              autoComplete="off"
                              size="small"
                              fullWidth
                              value={prefixo?.prefixo}
                              disabled={true}
                            />
                          </Box>
                        ))}
                    </Box>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>

          {windowWith < 640 ? (
            <Box>
              <List sx={styles.listContentMobile}>
                <Divider component="li" />
                {Array.isArray(rows) &&
                  _DATA.currentData().map((row, index) => (
                    <Box key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: "16px",
                          }}
                        >
                          <Typography variant="mySubtitle">
                            {row.razaoSocial}
                          </Typography>
                          <Box>
                            <Typography
                              sx={{ opacity: "0.8" }}
                              variant="caption"
                            >
                              ASN:{" "}
                            </Typography>
                            <Typography variant="caption">{row.as}</Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ opacity: "0.8" }}
                              variant="caption"
                            >
                              E-mail:{" "}
                            </Typography>
                            <Typography variant="caption">
                              {row?.email
                                ? row?.email
                                : row?.ClientesCadastro?.email || ""}
                            </Typography>
                          </Box>
                          <Chip
                            label={
                              row.ClientesCadastro?.status === 0
                                ? "Pendente"
                                : row.ClientesCadastro?.status === 1
                                ? "Completo"
                                : row.ClientesCadastro?.status === 2
                                ? "Em progresso"
                                : row.ClientesCadastro?.status === 3
                                ? "Ag. Revisão"
                                : row.ClientesCadastro?.status === 4
                                ? "Ag. Retorno"
                                : row.ClientesCadastro?.status === 5
                                ? "Arquivado"
                                : "Pendente"
                            }
                            size="medium"
                            sx={{
                              ...styles.chip,
                              bgcolor:
                                row.ClientesCadastro?.status === 0
                                  ? "chip.pending"
                                  : row.ClientesCadastro?.status === 1
                                  ? "chip.completed"
                                  : row.ClientesCadastro?.status === 2
                                  ? "chip.inProgress"
                                  : row.ClientesCadastro?.status === 3
                                  ? "chip.waitingReview"
                                  : row.ClientesCadastro?.status === 4
                                  ? "chip.waitingReturn"
                                  : row.ClientesCadastro?.status === 5
                                  ? "chip.arquive"
                                  : "chip.pending",
                              color: "white",
                              fontWeight: 600,
                            }}
                          />
                        </Box>
                        <Tooltip title={"Solicitações Pendentes"}>
                          <IconButton
                            sx={{ marginRight: "14px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              const thisRow = row;
                              setSelectedRow(thisRow);
                              setAnchorNotificacao(e.currentTarget);
                              setPendentes(
                                notificacoes?.filter(
                                  (item) => item?.clienteId === row?.id
                                )
                              );
                            }}
                          >
                            <Badge
                              badgeContent={
                                (notificacoes?.filter(
                                  (item) => item?.clienteId === row?.id
                                )).length
                              }
                              color="primary"
                              sx={styles.notificacoesMobileBadge}
                            >
                              <NotificationsOutlined />
                            </Badge>
                          </IconButton>
                        </Tooltip>

                        <IconButton
                          sx={{
                            bgcolor: "background.paper",
                            borderRadius: 0,
                            height: "135px",
                            ":focus": {
                              bgcolor: "background.paper",
                            },
                          }}
                          onClick={handleClick}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItem>
                      <Divider component="li" />
                    </Box>
                  ))}
              </List>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <Stack sx={{ margin: "auto" }} spacing={2}>
                  <Pagination
                    count={count}
                    page={page}
                    onChange={handleChangePage}
                  />
                </Stack>
              </Box>
            </Box>
          ) : (
            <DataGrid
              rows={filteredRows}
              columns={columns}
              autoHeight
              getRowHeight={() => "auto"}
              autosizeOptions={{ includeOutliers: true }}
              //apiRef={apiRef}
              pageSizeOptions={[25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              sx={{
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
              density="compact"
              disableColumnMenu
              disableRowSelectionOnClick
            />
          )}
        </Box>
      </Paper>

      <CadastroCliente
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleNewClient={handleNewClient}
        setSnackbar={setSnackbar}
      />
      <ModalEditCadastro
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleUpdatedClient={handleUpdatedClient}
        setSnackbar={setSnackbar}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={deleteLoad}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={() => {
          handleApiDeleteClientes(selectedRow?.id);
        }}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Container>
  );
}
