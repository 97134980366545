import {
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import GraficosResumo from "./charts";

export default function StatusCliente({
  ativos = 0,
  excedidos = 0,
  networkSummary = {},
  loadingCharts = true,
  selectedRow = {},
}) {
  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.boxHeader}>
        <Paper sx={styles.paperHeader} variant="outlined">
          <Typography whiteSpace={"nowrap"} variant="subtitle2">
            CLIENTES ATIVOS: {ativos}
          </Typography>
        </Paper>
        <Paper sx={styles.paperHeader} variant="outlined">
          <Typography whiteSpace={"nowrap"} variant="subtitle2">
            CLIENTES EXCEDIDOS: {excedidos}
          </Typography>
        </Paper>{" "}
      </Box>

      {loadingCharts ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}
        >
          <CircularProgress size={100} thickness={2.5} />
        </Box>
      ) : (
        <GraficosResumo
          networkSummary={networkSummary}
          loadingCharts={loadingCharts}
          selectedRow={selectedRow}
        />
      )}
    </Box>
  );
}
