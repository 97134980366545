import { Box, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

export default function LineChartComponent({
  percentile95 = 0,
  maxValuebps = 0,
  data = {},
  type = "",
}) {
  // Estilização
  const theme = useTheme();
  const unidade = "bps";

  // Conversão de dados
  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 B";
    const k = 1000;
    const sizes = ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  // Define dinamicamente o internvalo para os ticks
  function calcInterval() {
    let res;

    res = Math.floor(
      1440 / ((dayjs(data[1]?.date).unix() - dayjs(data[0]?.date).unix()) / 60)
    );

    return res;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          top: 20,
          right: 5,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(date, index) => dayjs(date).format("DD/MM")}
          interval={calcInterval() * 2}
          style={{ fontSize: "12px", fontWeight: 500, marginRight: "50px" }}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
        />
        <YAxis
          tickFormatter={formatBytes}
          interval={0}
          domain={[0, maxValuebps]}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
          style={{ fontSize: "12px", fontWeight: 500 }}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <Box
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark" ? "#07070752" : "#ffffffb4",
                    padding: "8px",
                  }}
                >
                  <Typography fontSize={"14px"}>
                    {`Data: ${dayjs(label).format("DD/MM HH:mm")}`}
                  </Typography>
                  <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
                    <Box
                      sx={{
                        bgcolor: "#00A76F",
                        width: "14px",
                        height: "14px",
                        borderRadius: "2px",
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    />
                    <Typography
                      fontSize={"14px"}
                    >{`Tráfego Limpo: ${formatBytes(
                      payload[0].payload.cleanTraffic
                    )}`}</Typography>
                  </Stack>
                </Box>
              );
            }

            return null;
          }}
        />

        <Area
          type="monotone"
          dataKey="cleanTraffic"
          stroke={theme.palette.mode === "dark" ? "#61ffca" : "#027951"}
          name="Tráfego"
          strokeWidth={2}
          fill="#00A76F"
          fillOpacity="1"
          stackId={1}
          dot={false}
        />
        {type === "percentil" && percentile95 > 0 && (
          <>
            <ReferenceLine y={percentile95} stroke="red" strokeWidth={1.5} />
          </>
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
}
