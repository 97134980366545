const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 1,
    height: "100%",
  },
  paper: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: 1,
    justifyContent: "space-between",
    maxWidth: "100%",
    position: "relative",
    height: "100%",
    pr: "11px",
  },
  boxChart: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    height: "100%",
    "@media (max-width: 1100px)": {
      height: "230px",
    },
  },
  cardSubText: {
    fontWeight: 500,
    fontSize: "16px",
    mb: 0.4,
    "@media (max-width: 600px)": {
      mb: 0.1,
    },
  },
});

export default styles;
