import { useTheme } from "@emotion/react";
import "dayjs/locale/pt-br";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Checkbox,
  Divider,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import api from "../../../../../services/api";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/pt-br";
import { useOutletContext } from "react-router-dom";
import useStyle from "./styles";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { renderToStaticMarkup } from "react-dom/server";
import { PageTemplateEasyReport } from "../../../../../components/generateReport";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import TrafficChart from "../../../../../components/relatorioPercentil/line/chartsTypes/traficChart";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.locale("pt-br");

export default function Configuracoes({ loading, setLoading = () => {} }) {
  const mesAtual = dayjs().format("MMMM"); // Nome do mês atual
  const mesPassado = dayjs().subtract(1, "month").format("MMMM");
  const theme = useTheme();
  const styles = useStyle(theme);
  const today = new Date();
  today?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  today?.setSeconds(0, 0);
  const twoMonths = new Date();
  twoMonths?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  twoMonths?.setMonth(today.getMonth() - 2);
  twoMonths?.setHours(0, 0, 0, 0);

  const [sb, clienteInfo, si] = useOutletContext();
  const [snackbar, setSnackbar] = useState(null);

  const [Null, setNull] = useState(false);
  const [valorExecedente, setValorExcedente] = useState();
  const [selectedMonth, setSelectedMonth] = useState("actual");
  const [grupos, setGrupos] = useState([]);
  const [selectGrupos, setSelectGrupos] = useState([]);
  const [rlGrupos, setRlGrupos] = useState([]);
  const [selectedFonte, setSelectedFonte] = useState("easyflow");
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [loadingPercentil, setLoadingPercentil] = useState(false);
  const [editConfig, setEditConfig] = useState(true);
  const [configPercentil, setConfigPercentil] = useState({
    percentil: "",
    permissao: "",
    valorExcedente: "",
    tarifacaoExcedente: "",
    tarifacaoFixa: "",
    cobrancaPorExcedente: "",
    clienteId: clienteInfo?.id,
    id: "",
  });
  const [formDataConfigPercentil, setFormDataConfigPercentil] = useState({
    percentil: "",
    permissao: "",
    valorExcedente: "",
    tarifacaoExcedente: "",
    tarifacaoFixa: "",
    cobrancaPorExcedente: "",
    clienteId: clienteInfo?.id,
    id: "",
  });
  const [formData, setFormData] = useState({
    titulo: "",
    status: 0,
    tipo: 0,
    dataInicial: "",
    dataFinal: "",
    clienteId: clienteInfo?.id,
    tarifacaoExcedente: {
      status: true,
      valor: "0",
      valorExcedente: "0",
      limite: "0",
    },
  });

  useEffect(() => {
    setFormDataConfigPercentil(configPercentil);
  }, [configPercentil]);

  useEffect(() => {
    if (clienteInfo.id) {
      getConfigPercentil();
      findManyRlGrupos();
      setFormData({
        titulo: clienteInfo?.nomeFantasia || "",
        status: 0,
        tipo: 0,
        dataInicial: dayjs(new Date()).startOf("month"),
        dataFinal: dayjs(Date())
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0),
        clienteId: clienteInfo?.id,
        tarifacaoExcedente: {
          status: true,
          valor: "0,00",
          valorExcedente: "0",
          limite: "0",
        },
      });
      findManyGrupos();
      setSelectGrupos([]);
    }
  }, [clienteInfo]);
  useEffect(() => {
    if (selectedMonth === "last") {
      let final_date = new Date();
      let initial_date = new Date();
      initial_date = dayjs(initial_date)
        .subtract(1, "month")
        .date(1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);
      final_date = dayjs(final_date)
        .date(1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);
      setFormData((prev) => ({
        ...prev,
        dataInicial: initial_date.toISOString(),
        dataFinal: final_date.toISOString(),
      }));
    } else {
      let final_date = new Date();
      let initial_date = new Date();
      initial_date = dayjs(initial_date).startOf("month");

      final_date = dayjs(final_date)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);

      setFormData((prev) => ({
        ...prev,
        dataInicial: initial_date.toISOString(),
        dataFinal: final_date.toISOString(),
      }));
    }
  }, [selectedMonth]);

  useEffect(() => {
    setValorExcedente(() => {
      const valor = (
        (parseFloat(formDataConfigPercentil?.tarifacaoExcedente) *
          convertNumero(formDataConfigPercentil?.tarifacaoFixa)) /
          100 || 0
      )
        ?.toFixed(3)
        ?.replace(".", ",");
      const valorInteiro = valor?.replace(/\D/g, "");
      const [partInt, partDec] = (valorInteiro / 1000).toFixed(3).split(".");
      return `${partInt?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${
        partDec?.slice(2, 3) === "0" ? partDec?.slice(0, 2) : partDec
      }`;
    });
  }, [
    formDataConfigPercentil?.tarifacaoExcedente,
    formDataConfigPercentil?.tarifacaoFixa,
  ]);

  async function handleApiGetBase64(htmlBase64) {
    try {
      const response = await api.post("/interno/relatorio/htmlToImageBase64", {
        htmlBase64,
      });

      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }

  const handleChangeFonte = (event) => {
    setSelectedFonte(event.target.value);
  };

  async function handleGenerateGraphPNG(dataIn, percentil) {
    let graphOut = {};
    let [data] = dataIn;
    let percentilClients = {};
    const { history, type, th95, trafficFull, limitTraffic } = data;
    let staticHtmlTraffic = renderToStaticMarkup(
      <TrafficChart
        data={history ? history : []}
        percentil={percentil}
        c95th={th95}
        limitTraffic={limitTraffic}
      />
    );
    percentilClients = {
      name: type,
      value: trafficFull,
      status: true,
    };
    let graphcs = await handleApiGetBase64({
      data: btoa(unescape(encodeURIComponent(staticHtmlTraffic))),
      //data: btoa(decodeURIComponent(encodeURIComponent(staticHtmlTraffic))),
      width: 790,
      height: 200,
    });

    graphOut = {
      client: data.client,
      type,
      graphcs: graphcs,
    };

    return graphOut;
  }

  const handleValorConfig = (e) => {
    setFormDataConfigPercentil((prev) => ({
      ...prev,
      tarifacaoFixa: mascara(e?.target?.value),
    }));
  };

  const handleValorExcedenteConfig = (e) => {
    setFormDataConfigPercentil((prev) => ({
      ...prev,
      tarifacaoExcedente: e?.target?.value?.replace(/\D/g, ""),
    }));
  };

  const handleLimiteTráfegoConfig = (e) => {
    setFormDataConfigPercentil((prev) => ({
      ...prev,
      valorExcedente: e?.target?.value?.replace(/\D/g, ""),
    }));
  };

  const mascara = (value) => {
    const valorInteiro = value?.replace(/\D/g, "");
    const [partInt, partDec] = (valorInteiro / 1000).toFixed(3).split(".");
    const valorFormatado = partInt
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return `${valorFormatado},${partDec}`;
  };

  const getConfigPercentil = async () => {
    setLoadingPercentil(true);
    try {
      const response = await api.post("/interno/percentilCliente", {
        clienteId: clienteInfo?.id,
      });
      let porcentagemExcedente = Math.round(
        (convertNumero(response?.data?.tarifacaoExcedente) * 100) /
          convertNumero(response?.data?.tarifacaoFixa)
      );

      setConfigPercentil((prev) => ({
        ...prev,
        ...response?.data,
        tarifacaoExcedente: porcentagemExcedente,
      }));
    } catch (error) {
      if (error?.response?.status == 404) {
        setSnackbar({
          children: `${
            error?.response?.data?.error ||
            "Error: Não foi possivel encontrar contratos"
          }`,
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      }
      setConfigPercentil({
        percentil: "90",
        permissao: "",
        valorExcedente: "",
        tarifacaoExcedente: "",
        tarifacaoFixa: "",
        cobrancaPorExcedente: true,
        clienteId: clienteInfo?.id,
        id: "",
      });
    } finally {
      setLoadingPercentil(false);
    }
  };

  const validacaoConfig = () => {
    if (!formDataConfigPercentil?.percentil) {
      setNull(true);
    } else {
      updateConfigPercentil();
      setNull(false);
    }
  };
  const updateConfigPercentil = async () => {
    setLoadingConfig(true);

    try {
      const response = await api.post("/interno/updatePercentilCliente", {
        percentil: parseInt(formDataConfigPercentil?.percentil),
        permissao: formDataConfigPercentil?.permissao,
        clienteId: clienteInfo?.id,
        id: formDataConfigPercentil?.id,
      });

      /*   let porcentagemExcedente = Math.round(
        (convertNumero(response?.data?.tarifacaoExcedente) * 100) /
          convertNumero(response?.data?.tarifacaoFixa)
      );
 */
      setConfigPercentil((prev) => ({
        ...prev,
        ...response?.data,
        tarifacaoExcedente: formDataConfigPercentil?.tarifacaoExcedente,
        tarifacaoFixa: formDataConfigPercentil?.tarifacaoFixa,
        valorExcedente: formDataConfigPercentil?.valorExcedente,
      }));
      setEditConfig(true);
      setSnackbar({
        children: "Configurações atualizadas",
        severity: "success",
      });
    } catch (erro) {
      console.error(erro);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoadingConfig(false);
    }
  };

  const handleEditConfig = () => {
    setEditConfig(false);
  };

  const handleCancelarConfig = () => {
    setEditConfig(true);
    setFormDataConfigPercentil((prev) => ({
      ...prev,
      percentil: configPercentil?.percentil,
      cobrancaPorExcedente: configPercentil?.cobrancaPorExcedente,
      permissao: configPercentil?.permissao,
    }));
  };
  const handleChangePercentil = (e) => {
    const regex = /^([0-9]|([1-9][0-9])|100)$/;
    let value = e?.target?.value;
    if (value === "" || regex.test(value)) {
      setFormDataConfigPercentil((prev) => ({
        ...prev,
        percentil: e?.target?.value?.replace(/\D/g, ""),
      }));
    }
  };

  const convertNumero = (value) => {
    return parseFloat(value?.replace(/\./g, "")?.replace(",", "."));
  };
  const handleDados = async () => {
    if (
      !formData?.titulo?.trim() ||
      !formDataConfigPercentil?.percentil ||
      !formDataConfigPercentil?.valorExcedente ||
      !formDataConfigPercentil?.tarifacaoExcedente ||
      !formDataConfigPercentil?.tarifacaoFixa ||
      !formData?.dataFinal ||
      !formData?.dataFinal
    ) {
      setNull(true);
    } else {
      setLoading(true);
      setEditConfig(true);
      let idGrupos = selectGrupos?.map((grupo) => grupo?.id);
      try {
        let graphcs = null;
        const response = await api.post("/interno/relatorio", {
          clienteId: clienteInfo?.id,
          initial_date: formData?.dataInicial,
          final_date: formData?.dataFinal,
          tarifacao: formDataConfigPercentil?.tarifacaoFixa,
          idGrupos: idGrupos,
          fonte: selectedFonte,
        });

        const relatorio = {
          titulo: formData?.titulo,
          client: response?.data?.client,
          status: 0,
          tipo: 1,
          dataInicial: formData?.dataInicial,
          dataFinal: formData?.dataFinal,
          hideCharts: false,
          percentil: formDataConfigPercentil?.percentil,
          analysis: response?.data?.analysis,
          interfaces: response?.data?.interfaces,
          tarifacaoExcedente: {
            status: formDataConfigPercentil?.cobrancaPorExcedente,
            valor: convertNumero(formDataConfigPercentil?.tarifacaoFixa),
            valorExcedente:
              convertNumero(valorExecedente) +
              convertNumero(formDataConfigPercentil?.tarifacaoFixa),
            limite: parseInt(formDataConfigPercentil?.valorExcedente),
          },
        };

        graphcs = await handleGenerateGraphPNG(
          relatorio.analysis,
          relatorio.percentil
        );

        const MyDoc = (
          <PageTemplateEasyReport relatorio={relatorio} graphcs={graphcs} />
        );
        let blob = await pdf(MyDoc).toBlob();
        saveAs(blob, `relatório ${relatorio?.client}.pdf`);

        setSnackbar({
          children: "Gerado relatório",
          severity: "success",
        });
        setNull(false);
      } catch (error) {
        let dataError = error?.response?.data;
        if (dataError) {
          setSnackbar({
            children: dataError?.message,
            severity: "error",
          });
        } else {
          setSnackbar({
            children: "Erro ao tentar gerar relatório",
            severity: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const findManyGrupos = async () => {
    try {
      const response = await api.post("/interno/findManyGrupoBlocosFlow", {
        clienteId: clienteInfo?.id,
      });
      setGrupos(response?.data);
    } catch (erro) {
      console.error(erro);
    } finally {
    }
  };

  const findManyRlGrupos = async () => {
    try {
      const response = await api.post("/interno/findManyRlGrupoBlocosFlow", {
        clienteId: clienteInfo?.id,
      });
      setRlGrupos(response?.data);
    } catch (erro) {
      console.error(erro);
    } finally {
    }
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;
  return (
    <>
      {loadingPercentil ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={styles.container}>
          <Paper
            sx={styles.containerPaper}
            elevation={3}
            variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="mySubtitle">
                Configurações de relatório
              </Typography>
              <FormControl
                variant="filled"
                sx={{ width: "150px" }}
                size="small"
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Fonte
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={selectedFonte}
                  onChange={handleChangeFonte}
                >
                  <MenuItem value="easyflow">EasyFlow</MenuItem>
                  <MenuItem value="easymon">EasyMon</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Paper variant="outlined" sx={styles.paper}>
              <Box>
                <Box sx={styles.stack}>
                  <TextField
                    sx={styles.valorMbps}
                    variant="filled"
                    size="small"
                    label="Percentil cliente"
                    value={formDataConfigPercentil?.percentil || ""}
                    disabled={editConfig}
                    onChange={handleChangePercentil}
                    helperText={
                      !formDataConfigPercentil?.percentil && Null
                        ? "Necessário informar percentil"
                        : ""
                    }
                    error={!formDataConfigPercentil?.percentil && Null}
                  />
                </Box>
                <Box sx={styles.containerConfig}>
                  <Box sx={styles.containerPermissao}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        disabled={editConfig}
                        checked={formDataConfigPercentil?.permissao}
                        onChange={(e) =>
                          setFormDataConfigPercentil((prev) => ({
                            ...prev,
                            permissao: e.target.checked,
                          }))
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Typography sx={editConfig && styles.opacityStyle}>
                        Permitir que este cliente possa gerar relatórios.
                      </Typography>
                    </Box>

                    <FormHelperText sx={{ ml: 1.5 }}>
                      O cliente está{" "}
                      {formDataConfigPercentil?.permissao
                        ? "habilitado"
                        : "desabilitado"}{" "}
                      para gerar relatórios
                    </FormHelperText>
                  </Box>
                  <Stack spacing={2} alignItems={"center"} direction={"row"}>
                    {editConfig ? (
                      <Stack
                        spacing={2}
                        alignItems={"end"}
                        sx={styles.containerButton}
                      >
                        <Button
                          onClick={handleEditConfig}
                          variant="contained"
                          size="medium"
                          disabled={loading}
                        >
                          Editar
                        </Button>
                      </Stack>
                    ) : (
                      <Stack
                        spacing={2}
                        alignItems={"end"}
                        sx={styles.containerButton}
                      >
                        <Box sx={styles.containerSaveButton}>
                          <LoadingButton
                            sx={styles.button}
                            onClick={() => {
                              validacaoConfig();
                            }}
                            variant="contained"
                            size="medium"
                            disabled={loadingConfig}
                          >
                            Salvar
                          </LoadingButton>

                          <Button
                            sx={styles.button}
                            onClick={handleCancelarConfig}
                            size="medium"
                          >
                            Cancelar
                          </Button>
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                </Box>
                <Divider sx={styles.divider} />
                <Box sx={styles.containerExcedente}>
                  <Typography>Cobrança por excedente?</Typography>
                  <RadioGroup
                    defaultValue="small"
                    name="radio-buttons-group"
                    value={formDataConfigPercentil?.cobrancaPorExcedente}
                    sx={styles.radioGrupo}
                  >
                    <Radio
                      value={false}
                      label="Nao"
                      size="md"
                      onChange={() =>
                        setFormDataConfigPercentil((prev) => ({
                          ...prev,
                          cobrancaPorExcedente: false,
                        }))
                      }
                      defaultChecked
                    />
                    <Typography>Não</Typography>
                    <Radio
                      value={true}
                      label="Sim"
                      size="md"
                      onChange={() =>
                        setFormDataConfigPercentil((prev) => ({
                          ...prev,
                          cobrancaPorExcedente: true,
                        }))
                      }
                    />
                    <Typography>Sim</Typography>
                  </RadioGroup>
                </Box>
                <Box sx={styles.stack}>
                  <TextField
                    label={"Valor por Mbps"}
                    size="small"
                    variant="filled"
                    sx={styles.valorMbps}
                    value={formDataConfigPercentil?.tarifacaoFixa || ""}
                    onChange={handleValorConfig}
                    onBlur={() => {
                      let [valorInt, valorDec] =
                        formDataConfigPercentil?.tarifacaoFixa
                          ?.split(",")
                          .slice(-2);

                      valorDec?.slice(2, 3) === "0" &&
                        setFormDataConfigPercentil((prev) => ({
                          ...prev,
                          tarifacaoFixa: `${valorInt},${valorDec?.slice(0, 2)}`,
                        }));
                    }}
                    startAdornment={
                      <InputAdornment position="start">R$</InputAdornment>
                    }
                    helperText={
                      !formDataConfigPercentil?.tarifacaoFixa && Null
                        ? "Necessário informar valor por Mbps"
                        : ""
                    }
                    error={!formDataConfigPercentil?.tarifacaoFixa && Null}
                  />

                  {formDataConfigPercentil?.cobrancaPorExcedente && (
                    <>
                      <Box sx={{ width: "100%" }}>
                        <TextField
                          variant="filled"
                          size="small"
                          label={"Limite de tráfego"}
                          value={formDataConfigPercentil?.valorExcedente || ""}
                          onChange={handleLimiteTráfegoConfig}
                          helperText={"Em Mbps"}
                          sx={{ width: "100%" }}
                          error={
                            !formDataConfigPercentil?.valorExcedente && Null
                          }
                        />
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <TextField
                          variant="filled"
                          size="small"
                          label={" Excedente em %"}
                          value={
                            formDataConfigPercentil?.tarifacaoExcedente || ""
                          }
                          onChange={handleValorExcedenteConfig}
                          helperText={`R$ ${valorExecedente}`}
                          sx={{ width: "100%" }}
                          error={
                            !formDataConfigPercentil?.tarifacaoExcedente && Null
                          }
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Paper>
            <Paper variant="outlined" sx={styles.paper}>
              <Box sx={styles.containerTitle}>
                <Stack direction={"column"} sx={{ width: "100%" }}>
                  <Typography sx={styles.subtitle} variant="subtitle2">
                    Título do relatório
                  </Typography>
                  <TextField
                    size="small"
                    label="Título"
                    variant="filled"
                    autoComplete="off"
                    value={formData?.titulo}
                    helperText={
                      !formData?.titulo?.trim() && Null
                        ? "Necessário informar um título"
                        : ""
                    }
                    disabled={loading}
                    error={!formData?.titulo?.trim() && Null}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        titulo: e.target.value,
                      }))
                    }
                  />
                </Stack>
              </Box>
              <Box sx={{ ...styles.containerTitle, marginTop: "10px" }}>
                {selectedFonte != "easymon" && (
                  <Stack direction={"column"} sx={{ width: "100%" }}>
                    <Typography sx={styles.subtitle} variant="subtitle2">
                      Grupo de blocos
                    </Typography>
                    <Autocomplete
                      multiple
                      size="small"
                      variant="filled"
                      options={grupos}
                      disableCloseOnSelect
                      value={selectGrupos}
                      getOptionDisabled={(option) =>
                        !rlGrupos?.some((grupo) => grupo === option?.id)
                      }
                      onChange={(event, value) => {
                        if (value?.length === 0) {
                          setSelectGrupos([]);
                        }
                      }}
                      getOptionLabel={(option) => option.grupoName}
                      renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;

                        return (
                          <li
                            key={key}
                            {...optionProps}
                            onClick={(e) =>
                              selected
                                ? setSelectGrupos((prev) =>
                                    prev?.filter(
                                      (grupo) => grupo?.id !== option?.id
                                    )
                                  )
                                : setSelectGrupos((prev) => [...prev, option])
                            }
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.grupoName}
                          </li>
                        );
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          const { key, onDelete, ...tagProps } = getTagProps({
                            index,
                          });

                          return (
                            <Chip
                              size="small"
                              sx={{
                                ...styles.chip,
                                bgcolor: option?.colorGrupo,
                              }}
                              key={key}
                              label={option.grupoName}
                              {...tagProps}
                              onDelete={() => {
                                setSelectGrupos((prev) =>
                                  prev?.filter(
                                    (grupo) => grupo?.id !== option?.id
                                  )
                                );
                              }}
                            />
                          );
                        })
                      }
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Grupos"
                          variant="filled"
                          helperText={
                            "Caso não seja adicionado nenhum grupo, será considerado todos os blocos"
                          }
                        />
                      )}
                    />
                  </Stack>
                )}
              </Box>
              <Divider sx={styles.divider} />
              <Box sx={styles.containerSelect}>
                <Typography
                  sx={{ ...styles.subtitle, mt: "10px" }}
                  variant="subtitle2"
                >
                  Intervalo de coleta
                </Typography>
                <Box sx={styles.containerDate}>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={selectedMonth || ""}
                    onChange={(e) => {
                      setSelectedMonth(e?.target?.value);
                    }}
                    disabled={loading}
                    size="small"
                  >
                    <MenuItem value={"actual"}>
                      {mesAtual[0]?.toUpperCase() + mesAtual?.substring(1)}
                    </MenuItem>
                    <MenuItem value={"last"}>
                      {mesPassado[0]?.toUpperCase() + mesPassado?.substring(1)}
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
              <Divider sx={styles.divider} />
              <Stack sx={styles.containerLoadindButton}>
                <LoadingButton
                  loading={loading}
                  onClick={handleDados}
                  variant="contained"
                  size="medium"
                >
                  Gerar relatório
                </LoadingButton>
              </Stack>
            </Paper>
          </Paper>

          {!!snackbar && (
            <Snackbar
              open
              onClose={() => setSnackbar(null)}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert {...snackbar} onClose={() => setSnackbar(null)} />
            </Snackbar>
          )}
        </Box>
      )}
    </>
  );
}
